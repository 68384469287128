import { client } from "@l2r-front/l2r-query"

import { useSvraiQuery } from "../../../../../common/hooks/queries/useSvraiQuery"
import { eventsQueryKeys } from "./queryKeys"

function initialData(
    uuid,
    queryParameters,
) {
    const queries = client.getQueriesData(eventsQueryKeys["charts"](uuid))
    for (const [_, queryData] of queries) {
        if (!queryData) {
            break
        }

        if (queryData[queryParameters.fields]) {
            return queryData
        }
    }
    return undefined
}

export function useEventChartQuery(
    uuid,
    queryParameters,
    config = {},
) {
    const url = `events/${uuid}/graphs/`

    return useSvraiQuery(
        url,
        eventsQueryKeys["chart"](uuid, queryParameters.fields),
        queryParameters,
        config,
        () => initialData(uuid, queryParameters),
    )
}
