import { useCallback } from "react"

import { useParams } from "react-router-dom"

import { ImageCollectionLayer, ImageSelectedLayer, useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { NetworkReferentialLayer, useNavigateToLinearLocation } from "@l2r-front/l2r-networks"

import { SELECTED_ROADWORK_URL_PARAM } from "../../constants/techniques"
import { RoadworksLayer } from "../../containers/RoadworksLayer"
import { useRoadworksDispatchContext } from "../../contexts/RoadworksContext"

export function RoadworksDetailsMapLayersMobile() {

    const params = useParams()
    const road = params?.road
    const navigateToLinearLocation = useNavigateToLinearLocation()
    const { setSelectedSegment, setSelectedRoadwork } = useRoadworksDispatchContext()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()

    const onReferentialFeatureClick = useCallback(feature => {
        setSegmentFeatureIdClicked(feature.properties.uuid)
        setSelectedSegment(feature)
        const linearLocation = JSON.parse(feature.properties.linearLocation)
        if (linearLocation && linearLocation[0].road !== road) {
            setSelectedRoadwork(null)
            navigateToLinearLocation(linearLocation[0], "..", { paramsToRemove: [SELECTED_ROADWORK_URL_PARAM] })
        }
    }, [
        setSegmentFeatureIdClicked,
        setSelectedSegment,
        setSelectedRoadwork,
        navigateToLinearLocation,
        road])

    return <>
        <NetworkReferentialLayer onClickOnFeature={onReferentialFeatureClick} />
        <RoadworksLayer />
        <ImageCollectionLayer road={road} />
        <ImageSelectedLayer />
    </>
}