import { ReactMapGl } from "@l2r-front/l2r-map"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { Map } from "../../../../common/containers/Map"
import * as Styled from "./MobileRoadwayMap.styled"

export function MobileRoadwayMap(props) {
    const {
        children,
        layerIndicator,
        ...mapProps
    } = props

    return (
        <Styled.Container>
            <Map {...mapProps}>
                {children}
            </Map>
            <Styled.LayerIndicatorWrapper>
                {layerIndicator}
            </Styled.LayerIndicatorWrapper>
        </Styled.Container>
    )
}

MobileRoadwayMap.propTypes = {
    ...ReactMapGl.propTypes,
    className: PropTypes.string,
    layerIndicator: PropTypes.element,
}