import { useQueryClient } from "@tanstack/react-query"

import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { roadworksQueryKeys } from "../queries/queryKeys"
import { useRoadworksProject } from "../queries/useRoadworksProjectQuery"
import { useRoadworksMutation } from "./useRoadworksMutation"

export function useRoadworkUpdate(
    roadwork,
    queryParameters,
    config = {},
) {
    const url = `roadworks/${roadwork?.uuid}/`
    const queryClient = useQueryClient()
    const { selectedNetwork } = useNetworksStateContext()
    const { data: roadworkProject } = useRoadworksProject()

    const { enabled = true } = config

    return useRoadworksMutation(
        url,
        axiosInstance.patch,
        {
            ...queryParameters,
        },
        {
            ...config,
            enabled: roadworkProject && roadwork?.uuid && enabled,
            onSuccess: (data) => {
                if (queryClient.getQueryData(roadworksQueryKeys["roadwork"](roadwork.uuid))) {
                    queryClient.setQueryData(roadworksQueryKeys["roadwork"](roadwork.uuid), data)
                }
                const roadworksQueryKeyFilter = roadworksQueryKeys.list()
                roadworksQueryKeyFilter.push({ network: selectedNetwork.slug })
                queryClient.invalidateQueries(roadworksQueryKeyFilter)

                const techniquesQueryKeyFilter = roadworksQueryKeys.techniques()
                techniquesQueryKeyFilter.push({ network: selectedNetwork.slug })
                queryClient.invalidateQueries(techniquesQueryKeyFilter)

                const roadworksLayerQueryKeyFilter = roadworksQueryKeys["layer"](roadworkProject.layer)
                queryClient.invalidateQueries(roadworksLayerQueryKeyFilter)

                if (roadwork.feature) {
                    const roadworkFeatureQueryKeyFilter = roadworksQueryKeys.section(roadwork.feature)
                    const previousSectionProperties = queryClient.getQueryData(roadworkFeatureQueryKeyFilter)?.properties
                    queryClient.invalidateQueries(roadworkFeatureQueryKeyFilter).then(() => {
                        if (config.keepProperties) {
                            queryClient.setQueriesData(roadworkFeatureQueryKeyFilter, (oldData) => {
                                return {
                                    ...oldData,
                                    properties: previousSectionProperties,
                                }
                            })
                        }
                    })
                }

                const yearsStatsQueryKey = roadworksQueryKeys["years_stats"]()
                queryClient.invalidateQueries(yearsStatsQueryKey)

                const quartersStatsQueryKey = roadworksQueryKeys["quartersStats"]()
                queryClient.invalidateQueries(quartersStatsQueryKey)
            },
        },
    )
}