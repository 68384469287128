import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { onErrorFeedback, onSuccessFeedback, useAlertsDispatchContext, useL2rQueries, useL2rQuery } from "@l2r-front/l2r-query"

export function useRoadworksQuery(
    url,
    queryKey,
    queryParameters,
    config = {},
    initialData,
) {
    const apiUrl = `roadwork/${url}`
    const { enabled = true, addProjectParam = true } = config
    const { selectedNetwork } = useNetworksStateContext()
    const { setSnackbar } = useAlertsDispatchContext()
    if (addProjectParam) {
        queryKey.push({ network: selectedNetwork?.slug })
    }

    return useL2rQuery(
        apiUrl,
        queryKey,
        { ...queryParameters, ...(addProjectParam && { project: selectedNetwork?.slug }) },
        {
            ...config,
            enabled: !!selectedNetwork?.slug && enabled,
            onError: () => onErrorFeedback(config, setSnackbar),
            onSuccess: () => onSuccessFeedback(config, setSnackbar),
        },
        initialData,
    )
}

export function useRoadworksQueries(queriesData) {
    const { selectedNetwork } = useNetworksStateContext()
    const { setSnackbar } = useAlertsDispatchContext()

    const overridedQueriesData = queriesData.map(queryData => {
        queryData.queryKey.push({ network: selectedNetwork?.slug })
        const { enabled = true } = queryData.config
        const newConfig = {
            ...queryData.config,
            enabled: !!selectedNetwork?.slug && enabled,
            onError: () => onErrorFeedback(queryData.config, setSnackbar),
            onSuccess: () => onSuccessFeedback(queryData.config, setSnackbar),
        }

        return {
            url: `roadwork/${queryData.url}`,
            queryKey: queryData.queryKey,
            queryParameters: { ...queryData.queryParameters, project: selectedNetwork?.slug },
            ...newConfig,
        }
    })

    return useL2rQueries(overridedQueriesData)
}