import { useCallback } from "react"

import { useSearchParams, useMatch, useParams } from "react-router-dom"

import { ImageCollectionLayer, ImageSelectedLayer, useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { NetworkReferentialLayer, useNavigateToLinearLocation } from "@l2r-front/l2r-networks"

import { APP_ROAD } from "../../../../common/constants/appRoad"
import { MODULE_ROADWAY } from "../../../../common/constants/modules"
import {
    DAMAGES_URL_PREFIX_PARAM,
    NETWORK_ASSESMENT_TYPE_URL_PARAM,
    NETWORK_GRADING_PREFIX_PARAM,
} from "../../../../common/constants/urlParams"
import { DamagesLayer } from "../../containers/DamagesLayer"
import { NotationLayer } from "../../containers/NotationLayer"
import { useRoadwayDispatchContext } from "../../contexts/RoadwayContext"

export function RoadwayMapLayersRouterMobile() {

    const [searchParams] = useSearchParams()
    const match = useMatch(`:slugId/${APP_ROAD}/${MODULE_ROADWAY}/:road`)
    const params = useParams()
    const road = params?.road
    const isSegmentView = !!match

    const navigateToLinearLocation = useNavigateToLinearLocation()
    const { setSelectedSegment } = useRoadwayDispatchContext()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()

    const currentNetworkAssesmentType = searchParams.get(NETWORK_ASSESMENT_TYPE_URL_PARAM)?.replace(/-(.*)/, "-") || null

    const onFeatureClick = useCallback(feature => {
        setSegmentFeatureIdClicked(feature.properties.uuid)
        setSelectedSegment(feature)
        const linearLocation = JSON.parse(feature.properties.linearLocation)
        if (linearLocation) {
            navigateToLinearLocation(linearLocation[0], "..", { saveMapBoundingBox: !isSegmentView })
        }
    }, [setSegmentFeatureIdClicked, setSelectedSegment, navigateToLinearLocation, isSegmentView])

    switch (currentNetworkAssesmentType) {
        case DAMAGES_URL_PREFIX_PARAM:
            return (
                <>
                    <NetworkReferentialLayer onClickOnFeature={onFeatureClick} />
                    <DamagesLayer isFocused={!isSegmentView} />
                    {isSegmentView && <ImageCollectionLayer road={road} />}
                    {isSegmentView && <ImageSelectedLayer />}
                </>
            )
        case NETWORK_GRADING_PREFIX_PARAM:
            return <>
                <NotationLayer onClickOnFeature={onFeatureClick} />
                {isSegmentView && <ImageCollectionLayer road={road} />}
                {isSegmentView && <ImageSelectedLayer />}
            </>
        default:
            return null
    }
}