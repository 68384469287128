import { Typography, styled, styledForMobile } from "@l2r-front/l2r-ui"

export const Container = styledForMobile("div")(({ theme, isMobile }) => ({
    backgroundColor: theme.palette["surfaces/surface-clair"].main,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(2),
    position: "absolute",
    bottom: isMobile ? 50 : 144,
    right: 10,
}))

export const Label = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(2),
}))
