export const onSuccessFeedback = (config, setSnackbar, ...values) => {

    if (config) {
        if (config.onSuccess) {
            config.onSuccess(...values)
        }

        if (config.successSnackbarMessage) {
            setSnackbar({
                label: config.successSnackbarMessage,
                severity: "success",
                anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom",
                },
                duration: config.successSnackbarDuration ?? 5000,
            })
        }
    }
    return null
}

export const onErrorFeedback = (config, setSnackbar, ...values) => {
    if (config) {
        if (config.onError) {
            config.onError(...values)
        }

        if (config.errorSnackbarMessage && setSnackbar) {
            setSnackbar({
                label: config.errorSnackbarMessage,
                severity: "error",
                anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom",
                },
                duration: 5000,
            })
        }
    }
    return null
}
