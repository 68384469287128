import { useCallback } from "react"

import { useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { NetworkReferentialLayer, useNavigateToLinearLocation } from "@l2r-front/l2r-networks"

import { SELECTED_TECHNIQUE_URL_PARAM } from "../../constants/techniques"
import { RoadworkTechniquesLayer } from "../../containers/RoadworkTechniquesLayer"
import { useRoadworksDispatchContext } from "../../contexts/RoadworksContext"

export function RoadworksMapLayersMobile() {
    const navigateToLinearLocation = useNavigateToLinearLocation()
    const { setSelectedSegment, setSelectedTechnique } = useRoadworksDispatchContext()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()

    const onReferentialFeatureClick = useCallback(feature => {
        setSegmentFeatureIdClicked(feature.properties.uuid)
        setSelectedSegment(feature)
        const linearLocation = JSON.parse(feature.properties.linearLocation)
        if (navigateToLinearLocation) {
            setSelectedTechnique(null)
            navigateToLinearLocation(linearLocation[0], "..", { saveMapBoundingBox: true, paramsToRemove: [SELECTED_TECHNIQUE_URL_PARAM] })
        }
    }, [
        setSegmentFeatureIdClicked,
        setSelectedSegment,
        setSelectedTechnique,
        navigateToLinearLocation])

    return <>
        <NetworkReferentialLayer onClickOnFeature={onReferentialFeatureClick} />
        <RoadworkTechniquesLayer />
    </>
}
