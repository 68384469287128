import { useContext } from "react"

import { SearchParamsDispatchContext } from "./SearchParamsContext.context"

export const useSearchParamsDispatchContext = () => {
    const context = useContext(SearchParamsDispatchContext)
    if (context === undefined) {
        throw new Error("useSearchParamsContext must be used within an SearchParamsContext")
    }
    return context
}
