import { useQueries, useQuery } from "@tanstack/react-query"

import { l2rApiAxiosInstance as axiosInstance } from "../constants/apis"

export async function queryFn(
    axiosInstance,
    url,
    queryParameters,
    config,
) {
    const response = await axiosInstance.get(url, {
        params: { ...queryParameters },
        ...config,
    })
    return response.data
}

export function useL2rQuery(url, queryKey, queryParameters, config, initialData) {
    const {
        fetchConfig,
        ...queryConfig
    } = (config || {})

    return useQuery(
        queryKey,
        () => queryFn(axiosInstance, url, queryParameters, fetchConfig),
        {
            ...queryConfig,
            initialData,
        },
    )
}

export function useL2rQueries(queriesData) {
    const queries = queriesData?.map(queryData => {
        const {
            fetchConfig,
            ...queryConfig
        } = (queryData.config || {})
        return {
            queryKey: queryData.queryKey,
            queryFn: () => queryFn(axiosInstance, queryData.url, queryData.queryParameters, fetchConfig),
            ...queryConfig,
        }
    })

    return useQueries({ queries: queries || [] })
}