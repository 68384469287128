import { onErrorFeedback, onSuccessFeedback, useAlertsContext } from "@l2r-front/l2r-query"

import { useGeoServerQueries, useGeoServerQuery } from "./useGeoServerQuery"

export function useGeoServerFeaturesList(queryKey, queryParameters, config) {
    const url = "features/"
    const { setSnackbar } = useAlertsContext()

    return useGeoServerQuery(
        url,
        queryKey,
        {
            ...queryParameters,
            geometry__isnull: !!queryParameters.geometry__isnull,
        },
        {
            ...config,
            onError: () => onErrorFeedback(config, setSnackbar),
            onSuccess: () => onSuccessFeedback(config, setSnackbar),
        })
}

export function useGeoServerFeaturesLists(queries) {

    const queriesData = queries.map(query => ({
        url: "features",
        ...query,
    }))

    return useGeoServerQueries(queriesData)
}
