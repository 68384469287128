import { useMemo, useState } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ModeEditIcon } from "@l2r-front/l2r-icons"
import { useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { useIsMobileDevice, Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { RoadworkSectionPainterModal } from "../../components/RoadworkSectionPainterModal"
import { STATUS_TODO } from "../../constants/status"
import { useRoadwork } from "../../hooks/queries/useRoadwork"
import { RoadworkSectionLength } from "../RoadworkSectionLength/RoadworkSectionLength"
import * as Styled from "./RoadworkSectionSidebarContent.styled"

export function RoadworkSectionSidebarContent(props) {

    const { className } = props
    const [modalOpen, setModalOpen] = useState(false)
    const isMobile = useIsMobileDevice()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { road, roadworkUuid } = useParams()

    const { data: roadwork } = useRoadwork(roadworkUuid, road)
    const readOnly = useRoleIsReadOnly()

    const isEditable = useMemo(() => {
        const currentYear = new Date().getFullYear()
        return roadwork && roadwork.year >= (currentYear - 1) && roadwork.status === STATUS_TODO && !readOnly
    }, [roadwork, readOnly])

    return <>
        <Styled.Container
            className={className}
            id="sidebar-technique-content-container"
            isMobile={isMobile}
        >
            <Styled.Header>
                <Styled.LocationHeader>
                    <Typography variant="H3">{t(I18N_NAMESPACE, "containers.roadworkSectionSidebarContent.location")}</Typography>
                    {isEditable && <Styled.FilledIconButton id="linear-edit-button" onClick={() => setModalOpen(true)}>
                        <ModeEditIcon sx={{ color: "white" }} />
                    </Styled.FilledIconButton>}
                </Styled.LocationHeader>
                <Styled.SegmentDetailInfo
                    isMobile={isMobile}
                    lengthLabel={t(I18N_NAMESPACE, "components.roadworkDetail.length")}
                    LengthComponent={<RoadworkSectionLength roadworkUuid={roadworkUuid} />} />
            </Styled.Header>
            <Styled.RoadworkDetailsForm />
        </Styled.Container>
        <RoadworkSectionPainterModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            title={t(I18N_NAMESPACE, "components.roadworkSectionPainterModal.update.title")}
            desc={t(I18N_NAMESPACE, "components.roadworkSectionPainterModal.update.desc")}
            cancelButtonLabel={t(I18N_NAMESPACE, "components.roadworkSectionPainterModal.update.cancel")}
            saveButtonLabel={t(I18N_NAMESPACE, "components.roadworkSectionPainterModal.update.save")}
        />
    </>
}