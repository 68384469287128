import { useCallback, useState } from "react"

import { useParams } from "react-router-dom"

import { useGeoServerFeaturesList, useGeoServerRetrieveFeature } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { Draw, STABILO_MAP_ID } from "@l2r-front/l2r-map"
import { NetworkReferentialLayer, useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CTAButton } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useRoadworkUpdate } from "../../hooks/mutations/useRoadworkUpdate"
import { roadworksQueryKeys } from "../../hooks/queries/queryKeys"
import { useRoadwork } from "../../hooks/queries/useRoadwork"
import { parametricsToLinearLocations } from "../../utils/parametricsToLinearLocations"
import { StabiloMap } from "../StabiloMap"
import * as Styled from "./RoadworkSectionPainter.styled"

export function RoadworkSectionPainter(props) {
    const {
        onClose,
        cancelButtonLabel,
        saveButtonLabel,
    } = props

    const [saveEnable, setSaveEnable] = useState(false)
    const [linearLocationsToSave, setLinearLocationsToSave] = useState([])
    const { selectedNetwork } = useNetworksStateContext()
    const { road, roadworkUuid } = useParams()
    const { t } = useTranslation(I18N_NAMESPACE)

    const { data: roadwork } = useRoadwork(roadworkUuid, road)

    const mutation = useRoadworkUpdate(roadwork, {}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.roadworkDetail.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.roadworkDetail.snackbar.success"),
        keepProperties: true,
    })

    const queryParams = {
        layer: selectedNetwork.referential.layerSection,
        linear_location_road: road,
    }
    const { data: roadFeatures } = useGeoServerFeaturesList(
        ["paintingRoad", queryParams],
        {
            ...queryParams,
            as_geojson: true,
        },
        {
            enabled: !!road && !!selectedNetwork.referential.layerSection,
        },
    )

    const { data: sectionSegments } = useGeoServerRetrieveFeature(roadworkUuid, roadworksQueryKeys.section(roadworkUuid), { as_geojson: true }, {})

    const handleFeaturesChanged = useCallback(featureCollection => {
        const linearLocations = parametricsToLinearLocations(roadFeatures, featureCollection.features)

        setSaveEnable(linearLocations.length > 0)
        setLinearLocationsToSave(linearLocations)
    }, [roadFeatures])

    const handleSave = useCallback(() => {
        if (linearLocationsToSave.length) {
            mutation.mutate({
                linearLocation: linearLocationsToSave.map(ll => ({
                    typeid: 1,
                    road: road,
                    start: ll[0],
                    end: ll[1],
                })),
            })
        }

        onClose()
    }, [onClose, linearLocationsToSave, mutation, road])

    if (!roadFeatures || !sectionSegments) {
        return null
    } else {
        return (
            <>
                <StabiloMap>
                    <NetworkReferentialLayer interactive={false} mapId={STABILO_MAP_ID} />
                    <Draw
                        opts={{ roadSegments: roadFeatures, startingFeatures: sectionSegments }}
                        onFeaturesChange={handleFeaturesChanged} />
                </StabiloMap>


                <Styled.ButtonsContainer>
                    <CTAButton id={"linear-edit-cancel"} variant="outlined" onClick={onClose}>{cancelButtonLabel}</CTAButton>
                    <CTAButton id={"linear-edit-ok"} disabled={!saveEnable} onClick={handleSave}>{saveButtonLabel}</CTAButton>
                </Styled.ButtonsContainer>
            </>
        )
    }
}

RoadworkSectionPainter.propTypes = {
    onClose: PropTypes.func.isRequired,
    cancelButtonLabel: PropTypes.string.isRequired,
    saveButtonLabel: PropTypes.string.isRequired,
}