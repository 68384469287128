import { useMemo } from "react"

import lodash from "lodash"

import {
    Layer,
    MapStyles,
    ROADWORK_LAYER,
    ROADWORK_SOURCE,
    getInterpolatedSelectableLineLayerWidth,
    useMapStateContext,
    useOrderingLayers,
} from "@l2r-front/l2r-map"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { useTheme } from "@l2r-front/l2r-ui"

import { ROADWORK_LAYER_WIDTH, getRoadworksLayerStyle } from "../../constants/getRoadworkConfig"
import { useRoadworksStateContext } from "../../contexts/RoadworksContext"
import { RoadworksSource } from "../RoadworksSource"

export function RoadworksLayer() {

    const theme = useTheme()
    const { currentMapStyle } = useMapStateContext()
    const { selectedLinearLocations } = useNetworksStateContext()
    useOrderingLayers([ROADWORK_LAYER])
    const { filters, selectedRoadwork } = useRoadworksStateContext()

    const roadworksLayerConfig = useMemo(() => {
        const referentialColor = currentMapStyle === MapStyles.plan ? theme.palette.map.darkReferential
            : theme.palette.map.lightReferential
        const selectedRoad = selectedLinearLocations?.[0]?.road

        return lodash.merge(
            getRoadworksLayerStyle(referentialColor, selectedRoadwork, selectedRoad, filters),
            {
                "paint": {
                    "line-width": getInterpolatedSelectableLineLayerWidth(selectedRoad, ROADWORK_LAYER_WIDTH),
                },
            },
        )
    }, [currentMapStyle, filters, selectedLinearLocations, selectedRoadwork, theme.palette])

    return <RoadworksSource id={ROADWORK_SOURCE}>
        <Layer
            {...roadworksLayerConfig}
            id={ROADWORK_LAYER}
        />
    </RoadworksSource>
}