import { useQueryClient } from "@tanstack/react-query"

import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { useSvraiMutation } from "../../../../../common/hooks/mutations/useSvraiMutation"
import { eventsQueryKeys } from "../../queries/events/queryKeys"
import { reportsQueryKeys } from "../../queries/reports/queryKeys"

export function useReportStepUpdate(
    stepId,
    eventId,
    queryParameters,
    config = {},
) {
    const url = `report_steps/${stepId}/`
    const queryClient = useQueryClient()

    return useSvraiMutation(
        url,
        axiosInstance.patch,
        {
            ...queryParameters,
        },
        {
            ...config, onSuccess: async () => {
                queryClient.invalidateQueries(eventsQueryKeys["detail"](eventId))
                queryClient.invalidateQueries(reportsQueryKeys["step"](stepId))
                queryClient.refetchQueries(eventsQueryKeys["detail"](eventId))
            },
        },
    )
}