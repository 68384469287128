import { useMemo } from "react"

import { useSearchParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useSearchParamsDispatchContext } from "@l2r-front/l2r-utils"

import { TabulatedLayout } from "../../layouts/TabulatedLayout"

export function MobileModulePage(props) {
    const {
        className,
        paramKey,
        tabChangeCallback,
        tabsConfig,
    } = props

    const {
        setParam,
    } = useSearchParamsDispatchContext()
    const [searchParams] = useSearchParams()
    const tabParam = searchParams.get(paramKey)

    const tabValue = useMemo(() => {
        const tabIndex = tabsConfig.findIndex(tabConfig => tabConfig.id === tabParam)
        return tabIndex > -1 ? tabIndex : 0
    }, [tabParam, tabsConfig])

    const handleChange = (_, newValue) => {
        if (tabChangeCallback) {
            tabChangeCallback(tabsConfig[tabValue]?.id, tabsConfig[newValue]?.id)
        }
        setParam(paramKey, tabsConfig[newValue]?.id)
    }

    return (
        <TabulatedLayout
            className={className}
            handleChange={handleChange}
            tabsConfig={tabsConfig}
            value={tabValue}
        />
    )
}

MobileModulePage.propTypes = {
    className: PropTypes.string,
    paramKey: PropTypes.string.isRequired,
    tabsConfig: PropTypes.arrayOf(PropTypes.shape({
        element: PropTypes.element,
        icon: PropTypes.element,
        id: PropTypes.string,
        label: PropTypes.string,

    }).isRequired),
}