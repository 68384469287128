import { useCallback } from "react"

import { useMatch } from "react-router-dom"

import { ImageCollectionLayer, ImageSelectedLayer, useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { NetworkReferentialLayer, useNavigateToLinearLocation } from "@l2r-front/l2r-networks"

import { APP_ROAD } from "../../../../common/constants/appRoad"
import { MODULE_ROADWORKS } from "../../../../common/constants/modules"
import { SELECTED_ROADWORK_URL_PARAM, SELECTED_TECHNIQUE_URL_PARAM } from "../../constants/techniques"
import { RoadworkSectionLayer } from "../../containers/RoadworkSectionLayer"
import { useRoadworksDispatchContext } from "../../contexts/RoadworksContext"

export function RoadworksSectionMapLayersMobile() {

    const match = useMatch(`:slugId/${APP_ROAD}/${MODULE_ROADWORKS}/:road/*`)
    const isSegmentView = !!match
    const road = decodeURIComponent(match?.params?.road || "")
    const navigateToLinearLocation = useNavigateToLinearLocation()
    const { setSelectedSegment, setSelectedTechnique, setSelectedRoadwork } = useRoadworksDispatchContext()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()

    const onReferentialFeatureClick = useCallback(feature => {
        setSegmentFeatureIdClicked(feature.properties.uuid)
        setSelectedSegment(feature)
        const linearLocation = JSON.parse(feature.properties.linearLocation)
        if (linearLocation && linearLocation[0].road !== road) {
            setSelectedTechnique(null)
            setSelectedRoadwork(null)
            navigateToLinearLocation(linearLocation[0], "..", { saveMapBoundingBox: !isSegmentView, paramsToRemove: [SELECTED_TECHNIQUE_URL_PARAM, SELECTED_ROADWORK_URL_PARAM] })
        }
    }, [
        setSegmentFeatureIdClicked,
        setSelectedSegment,
        setSelectedTechnique,
        setSelectedRoadwork,
        navigateToLinearLocation,
        isSegmentView,
        road])

    return <>
        <NetworkReferentialLayer onClickOnFeature={onReferentialFeatureClick} />
        <RoadworkSectionLayer />
        <ImageCollectionLayer road={road} />
        <ImageSelectedLayer />
    </>
}