import { useCallback, useMemo } from "react"

import { useSearchParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { SearchParamsDispatchContext } from "./SearchParamsContext.context"

export const SearchParamsContextProvider = (props) => {
    const { children } = props
    const [searchParams, setSearchParams] = useSearchParams()

    const deleteParam = useCallback((paramKey) => {
        searchParams.delete(paramKey)
        setSearchParams(searchParams, { replace: true })
    }, [searchParams, setSearchParams])

    const deleteParams = useCallback(() => {
        setSearchParams(new URLSearchParams(), { replace: true })
    }, [setSearchParams])

    const getParam = useCallback((paramKey) => {
        return searchParams.get(paramKey)
    }, [searchParams])

    const getParams = useCallback(() => {
        return searchParams
    }, [searchParams])

    const setParam = useCallback((paramKey, value) => {
        searchParams.set(paramKey, value)
        setSearchParams(searchParams, { replace: true })
    }, [searchParams, setSearchParams])

    const setParams = useCallback((params) => {
        setSearchParams(params, { replace: true })
    }, [setSearchParams])

    const dispatchValue = useMemo(() => {
        return {
            deleteParam,
            deleteParams,
            getParam,
            getParams,
            setParam,
            setParams,
        }
    }, [deleteParam,
        deleteParams,
        getParam,
        getParams,
        setParam,
        setParams,
    ])

    return (
        <SearchParamsDispatchContext.Provider value={dispatchValue}>
            {children}
        </SearchParamsDispatchContext.Provider>
    )
}

SearchParamsContextProvider.propTypes = {
    children: PropTypes.node,
}
