import { useMutation } from "@tanstack/react-query"

async function mutationFn(
    axiosFn,
    url,
    config,
    values,
) {
    const headers = config?.headers
    const response = await axiosFn(url, values, headers)
    return response.data
}

export function useL2rMutation(
    url,
    axiosFn,
    _,
    config = {},
) {
    return useMutation(
        (value) => mutationFn(axiosFn, url, config, value),
        config,
    )
}