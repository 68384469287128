import { useIsFetching } from "@tanstack/react-query"

import { DAMAGES_ROOT_QUERY_KEY } from "./queries/damages/queryKeys"
import { NETWORK_GRADINGS_ROOT_QUERY_KEY } from "./queries/networkGradings/queryKeys"

export function useIsFetchingRoadway() {
    const isFetchingNetworkGradingsLayers = useIsFetching([NETWORK_GRADINGS_ROOT_QUERY_KEY, "layer"])
    const isFetchingLinearLocations = useIsFetching([NETWORK_GRADINGS_ROOT_QUERY_KEY, "linearLocations"])
    const isFetchingSegments = useIsFetching([NETWORK_GRADINGS_ROOT_QUERY_KEY, "segments"])
    const isFetchingdamagesLayers = useIsFetching([DAMAGES_ROOT_QUERY_KEY, "layer"])

    const isFetching = isFetchingNetworkGradingsLayers
        || isFetchingLinearLocations
        || isFetchingSegments
        || isFetchingdamagesLayers
    return isFetching
}