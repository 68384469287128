import { EngineeringRoundedIcon, RoadIcon } from "@l2r-front/l2r-icons"
import { LINEAR_ABSOLUTE_END_URL_PARAM, LINEAR_ABSOLUTE_START_URL_PARAM } from "@l2r-front/l2r-networks"
import { useHandleBack } from "@l2r-front/l2r-utils"

import { L2rAppRoad } from "../../app"
import { NotationEPDKpi } from "../../modules/roadway/containers/NotationEPDKpi"
import { NotationTrendingKpi } from "../../modules/roadway/containers/NotationTrendingKpi"
import { SELECTED_TECHNIQUE_URL_PARAM } from "../../modules/roadworks/constants/techniques"
import { RoadworksBudgetsKpi } from "../../modules/roadworks/containers/RoadworksBudgetsKpi/RoadworksBudgetsKpi"
import { RoadworksYearlyBudgetKpi } from "../../modules/roadworks/containers/RoadworksYearlyBudgetKpi"
import { ProjectContextProvider } from "../contexts/ProjectContext"
import { getModulesBreadcrumb } from "../utils/getModulesBreadcrumb"
import { I18N_NAMESPACE } from "./i18n"
import { MODULE_ROADWAY, MODULE_ROADWORKS } from "./modules"
import { NETWORK_ASSESMENT_TYPE_URL_PARAM } from "./urlParams"

export const APP_ROAD = "road"

export const appRoad = {
    color: "cta-bg/cta-bg-primary",
    component: <L2rAppRoad />,
    externalContexts: [<ProjectContextProvider />],
    i18nNamespace: I18N_NAMESPACE,
    label: "app.label",
    modules: {
        roadway: {
            icon: RoadIcon,
            id: "ROADWAY",
            kpis: [{
                component: NotationEPDKpi,
                mobileOrder: 1,
                parent: "sidebar",
                position: 1,
            },
            {
                component: NotationTrendingKpi,
                grid: "1 / 2 / 3 / 2",
                mobileHeight: 470,
                mobileOrder: 3,
                height: "100%",
                parent: "list",
            }],
            label: "app.modules.roadway",
            path: MODULE_ROADWAY,
            useHandleBack: () => useHandleBack(getModulesBreadcrumb),
            urlParams: [LINEAR_ABSOLUTE_START_URL_PARAM, LINEAR_ABSOLUTE_END_URL_PARAM, NETWORK_ASSESMENT_TYPE_URL_PARAM],
        },
        roadworks: {
            icon: EngineeringRoundedIcon,
            id: "ROADWORK",
            kpis: [{
                component: RoadworksBudgetsKpi,
                grid: "1 / 1 / 3 / 1",
                height: "100%",
                mobileHeight: 470,
                mobileOrder: 2,
                parent: "list",
            },
            {
                component: RoadworksYearlyBudgetKpi,
                grid: "3 / 1 / 3 / 3",
                height: "100%",
                mobileHeight: "fit-content",
                mobileOrder: 4,
                parent: "list",
            }],
            label: "app.modules.works",
            path: MODULE_ROADWORKS,
            useHandleBack: () => useHandleBack(getModulesBreadcrumb),
            urlParams: [LINEAR_ABSOLUTE_START_URL_PARAM, LINEAR_ABSOLUTE_END_URL_PARAM, SELECTED_TECHNIQUE_URL_PARAM],
        },
    },
    path: APP_ROAD,
}