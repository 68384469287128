import { useMemo } from "react"

import { useLocation, useNavigate, matchRoutes, useSearchParams } from "react-router-dom"

export const useHandleBack = (getBreadcrumbPaths) => {

    const navigate = useNavigate()
    const location = useLocation()
    const [params] = useSearchParams()

    const breadcrumbPaths = useMemo(() => {
        return getBreadcrumbPaths(params, location)
    }, [params, getBreadcrumbPaths, location])

    const matches = matchRoutes(breadcrumbPaths, location)

    const newPath = useMemo(() => {
        if (matches) {
            const newRoutes = matches[0].route.newPath
            const routeParts = newRoutes.split("/")
            const matchedRoutesParts = routeParts.map(rp => {
                if (rp.startsWith(":")) {
                    const matchString = rp.slice(1)
                    return matches[0].params[matchString]
                }

                return rp
            })
            return matchedRoutesParts.join("/")
        }
        return "/"
    }, [matches])

    return () => navigate(newPath)
}

