import { useMemo } from "react"

import { useTheme } from "@emotion/react"
import { useParams } from "react-router-dom"

import {
    Layer,
    MapStyles,
    ROADWORK_LAYER,
    ROADWORK_SOURCE,
    useMapStateContext,
    useOrderingLayers,
} from "@l2r-front/l2r-map"

import { getRoadworkTechniquesLayerStyle } from "../../constants/getRoadworkConfig"
import { RoadworkSectionSource } from "../RoadworkSectionSource"

export function RoadworkSectionLayer() {

    const theme = useTheme()
    const { currentMapStyle } = useMapStateContext()
    useOrderingLayers([ROADWORK_LAYER])
    const { road } = useParams()

    const roadworksLayerConfig = useMemo(() => {
        const referentialColor = currentMapStyle === MapStyles.plan ? theme.palette.map.darkReferential
            : theme.palette.map.lightReferential

        return getRoadworkTechniquesLayerStyle(referentialColor, null, road)
    }, [currentMapStyle, road, theme.palette])


    return <RoadworkSectionSource id={ROADWORK_SOURCE}>
        <Layer
            {...roadworksLayerConfig}
            id={ROADWORK_LAYER}
            interactive={false}
        />
    </RoadworkSectionSource>
}