import { useCallback, useEffect, useMemo, useState } from "react"

import { useResolvedPath, useMatch, useSearchParams, useNavigate } from "react-router-dom"

import { useMyUserContext } from "@l2r-front/l2r-auth"
import { useDateFormatter, useDateLocale, useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useAlertsDispatchContext } from "@l2r-front/l2r-query"
import { Skeleton, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { FILTERS_SEARCH_PARAM_PREFIX, INITIAL_TIMESLOT_FILTER, PAGE_SEARCH_PARAM_PREFIX } from "../../../modules/incidents/constants/filters"
import { I18N_NAMESPACE } from "../../constants/i18n"
import { MODULE_INCIDENTS } from "../../constants/modules"
import { useProjectStateContext } from "../../contexts/ProjectContext"
import { initialStateContext, SvraiDispatchContext, SvraiStateContext } from "./SvraiContext.context"
import { deleteSearchParamsByKeys, getFiltersFromSearchParams, getFiltersSearchParams, getQueryParamsFromFilters } from "./SvraiContext.helpers"

const SNACKBAR_ERROR_DURATION = 5 * 1000

export const SvraiContextProvider = (props) => {
    const { children } = props
    const [svraiState, setSvraiState] = useState(initialStateContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const { selectedNetwork } = useNetworksStateContext()
    const { projectStartDate, projectEndDate } = useProjectStateContext()
    const locale = useDateLocale()
    const resolvedPath = useResolvedPath(`${MODULE_INCIDENTS}/:uuid/*`)
    const matchUrl = useMatch(resolvedPath.pathname)
    const dateFormatter = useDateFormatter()
    const { user } = useMyUserContext()
    const { setSnackbar } = useAlertsDispatchContext()
    const navigate = useNavigate()
    const isMobile = useIsMobileDevice()
    const { t } = useTranslation(I18N_NAMESPACE)

    const userIsStaff = useMemo(() => {
        return user && user.isStaff
    }, [user])

    const initialFilters = useMemo(() => {
        return ({
            date: {
                periodStartDate: projectStartDate,
                periodEndDate: projectEndDate,
                timeSlotStartHour: INITIAL_TIMESLOT_FILTER,
                timeSlotEndHour: INITIAL_TIMESLOT_FILTER,
            },
            tags: [],
        })
    }, [projectStartDate, projectEndDate])

    useEffect(() => {
        setSvraiState({
            lastIncidentPage: null,
            selectedIncident: null,
            filters: initialFilters,
        })
    }, [selectedNetwork.slug, initialFilters])

    const selectedIncidentUuid = useMemo(() => {
        return matchUrl?.params?.uuid || null
    }, [matchUrl])

    const setLastIncidentPage = useCallback((currentIncidentPage, replace) => {
        setSvraiState(value => ({
            ...value,
            lastIncidentPage: currentIncidentPage,
        }))
        searchParams.set(PAGE_SEARCH_PARAM_PREFIX, currentIncidentPage)
        setSearchParams(searchParams, replace && { replace: true })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSvraiState])

    useEffect(() => {
        setLastIncidentPage(1, true)
    }, [setLastIncidentPage])

    useEffect(() => {
        const { filtersFromParams, errorMessage } = getFiltersFromSearchParams(searchParams, initialFilters, userIsStaff, setSearchParams)
        if (errorMessage) {
            setSnackbar({
                label: t(I18N_NAMESPACE, errorMessage),
                duration: SNACKBAR_ERROR_DURATION,
                severity: "warning",
            })
        }
        const existingFiltersFromParams = Boolean(Object.keys(filtersFromParams || {}).length)

        if (existingFiltersFromParams) {
            return setSvraiState(value => ({
                ...value,
                filters: {
                    ...initialFilters,
                    ...filtersFromParams,
                },
            }))
        }
        return setSvraiState(value => ({
            ...value,
            filters: initialFilters,
        }))
    }, [initialFilters, searchParams, setSnackbar, setSearchParams, t, userIsStaff])

    const setFilters = useCallback((filters) => {
        setSvraiState(value => ({
            ...value,
            filters: filters,
            lastIncidentPage: 1,
        }))

        const newSearchParams = getFiltersSearchParams(filters, searchParams, dateFormatter, initialFilters)
        newSearchParams.set(PAGE_SEARCH_PARAM_PREFIX, 1)
        setLastIncidentPage(1)
        setSearchParams(newSearchParams, { replace: "true" })
        return searchParams
    }, [dateFormatter, initialFilters, searchParams, setSearchParams, setLastIncidentPage])

    const resetFilters = useCallback(() => {
        setFilters(initialFilters)
        const newSearchParams = deleteSearchParamsByKeys(searchParams, [FILTERS_SEARCH_PARAM_PREFIX + "*", PAGE_SEARCH_PARAM_PREFIX + "*"])

        if (!isMobile) {
            navigate(newSearchParams)
        }
        return newSearchParams
    }, [initialFilters, isMobile, navigate, searchParams, setFilters])

    const isReady = useCallback(() => {
        return svraiState?.filters?.date
    }, [svraiState])

    const getFiltersQueryParams = useCallback(() => {
        return getQueryParamsFromFilters(svraiState.filters, locale, dateFormatter, userIsStaff)

    }, [dateFormatter, locale, svraiState.filters, userIsStaff])

    const dispatchValue = useMemo(() => {
        return { getFiltersQueryParams, isReady, resetFilters, setLastIncidentPage, setFilters }
    }, [getFiltersQueryParams, isReady, resetFilters, setLastIncidentPage, setFilters])

    const stateValue = useMemo(() => {
        return ({
            ...svraiState,
            initialFilters,
            selectedIncidentUuid,
        })
    }, [svraiState, initialFilters, selectedIncidentUuid])

    return (
        <SvraiStateContext.Provider value={stateValue}>
            <SvraiDispatchContext.Provider value={dispatchValue}>
                {
                    isReady() ? children : <Skeleton />
                }
            </SvraiDispatchContext.Provider>
        </SvraiStateContext.Provider>
    )
}

SvraiContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
