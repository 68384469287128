import { useCallback, useEffect, useMemo } from "react"

import { useNavigate, useSearchParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useAlertsDispatchContext } from "@l2r-front/l2r-query"
import { Typography } from "@l2r-front/l2r-ui"

import { HeaderWithLogo } from "../../components/HeaderWithLogo"
import { I18N_NAMESPACE } from "../../constants/i18n"
import { RecoverPasswordDoneFormPost } from "../../forms/RecoverPasswordDoneForm"
import * as Styled from "./RecoverPasswordDonePage.styled"

export const RecoverPasswordDonePage = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { setSnackbar } = useAlertsDispatchContext()

    const { t } = useTranslation([I18N_NAMESPACE])

    const token = useMemo(() => {
        return searchParams.get("token")
    }, [searchParams])

    const redirectToLoginPage = useCallback(() => {
        setSnackbar({ label: t(I18N_NAMESPACE, "pages.recoverPasswordDonePage.save"), severity: "success" })
        navigate("/login")
    }, [navigate, setSnackbar, t])

    useEffect(() => {
        if (!token) {
            redirectToLoginPage()
        }
    }, [token, redirectToLoginPage])

    return (
        <Styled.PageWrapper>
            <HeaderWithLogo>
                <Typography
                    id="recover-password-done-label"
                    variant="H2"
                >
                    {t(I18N_NAMESPACE, "pages.recoverPasswordDonePage.label")}
                </Typography>
                <RecoverPasswordDoneFormPost
                    token={token}
                    onSuccess={redirectToLoginPage}
                />
            </HeaderWithLogo>
        </Styled.PageWrapper>
    )
}
