import { useCallback } from "react"

import { useNavigate, useSearchParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { SidebarContent } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { NETWORK_ASSESMENT_TYPE_URL_PARAM, NETWORK_GRADING_URL_PARAM } from "../../../../common/constants/urlParams"
import { useRoadwayDispatchContext } from "../../contexts/RoadwayContext"
import { RoadwayDetailSidebarContent } from "../RoadwayDetailSidebarContent"

export function RoadwayDetailSidebar() {

    const { t } = useTranslation([I18N_NAMESPACE])
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const { setSelectedSegment } = useRoadwayDispatchContext()

    const navigateBack = useCallback(() => {
        const networkGrading = searchParams.get(NETWORK_GRADING_URL_PARAM)
        const currentNetworkAssesmentType = searchParams.get(NETWORK_ASSESMENT_TYPE_URL_PARAM)

        setSelectedSegment(null)
        navigate(`..?${NETWORK_GRADING_URL_PARAM}=${networkGrading}&${NETWORK_ASSESMENT_TYPE_URL_PARAM}=${currentNetworkAssesmentType}`)
    }, [navigate, searchParams, setSelectedSegment])

    return (
        <SidebarContent
            id="roadwayDetail-sidebar"
            title={t(I18N_NAMESPACE, "containers.roadwayDetailSidebar.title")}
            onBack={navigateBack}
        >
            <RoadwayDetailSidebarContent />
        </SidebarContent>

    )
}
