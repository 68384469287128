import { Route, Routes } from "react-router-dom"

import { RoadwaySidebarRouter } from "../../../modules/roadway/routers/RoadwaySidebarRouter"
import { RoadworksSidebarRouter } from "../../../modules/roadworks/routers/RoadworksSidebarRouter"
import { MODULE_ROADWAY, MODULE_ROADWORKS } from "../../constants/modules"

export const SidebarRouter = () => {

    return <Routes>
        <Route
            element={<RoadwaySidebarRouter />}
            path={`/${MODULE_ROADWAY}/*`}
        />
        <Route
            element={<RoadworksSidebarRouter />}
            path={`/${MODULE_ROADWORKS}/*`}
        />
    </Routes>
}