import { useMemo } from "react"

import * as turf from "@turf/turf"

import { sanitizeData } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { Source, useMapDispatchContext } from "@l2r-front/l2r-map"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { impactsOrder } from "../../constants/impacts"
import { statusesSettings } from "../../constants/status"
import { useNetworkRoadworkLayer } from "../../hooks/queries/useNetworkRoadworkLayer"
import { useRoadworksProject } from "../../hooks/queries/useRoadworksProjectQuery"

export function RoadworksSource(props) {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { setError: setMapError } = useMapDispatchContext()

    const { data: roadworkProject } = useRoadworksProject()

    const { data, isError } = useNetworkRoadworkLayer(roadworkProject?.layer, {}, {
        enabled: !!roadworkProject?.layer,
        onError: () => setMapError(true),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.roadworksLayer.error"),
    })
    // Need to copy id into properties because of that mapbox issue:
    // link:https://github.com/mapbox/mapbox-gl-js/issues/2716
    const sanitizedData = useMemo(() => {
        if (!data || isError) {
            return null
        }

        const sanitized = sanitizeData(data)
        return orderZIndex(sanitized)
    }, [data, isError])

    if (!sanitizedData) {
        return null
    }

    return (
        <Source {...props} type="geojson" data={sanitizedData} tolerance={.001} />
    )
}

function orderZIndex(featureCollection) {
    const orderedFeatures = featureCollection?.features?.sort((a, b) => {
        if (a?.properties?.status !== b?.properties?.status) {
            return statusesSettings[a?.properties?.status]?.order - statusesSettings[b?.properties?.status]?.order
        }

        if (a?.properties?.technique?.impact !== b?.properties?.technique?.impact) {
            return impactsOrder[a?.properties?.technique?.impact] - impactsOrder[b?.properties?.technique?.impact]
        }

        if (a?.properties?.year !== b?.properties?.year) {
            return a?.properties?.year - b?.properties?.year
        }

        return a?.properties?.technique?.cost - b?.properties?.technique?.cost
    }).map((feature, index) => {
        return ({
            ...feature,
            properties: {
                ...feature.properties,
                zIndex: index,
            },
        })
    })
    return turf.featureCollection(orderedFeatures)
}
