import { LayersIcon } from "@l2r-front/l2r-icons"
import { Card, Skeleton, styled, styledForMobile } from "@l2r-front/l2r-ui"

export const RoadInfoItemSkeleton = styledForMobile(Skeleton)(({ isMobile, theme }) => ({
    height: 70,
    padding: theme.spacing(2, 3),
    position: "absolute",
    right: isMobile ? 0 : 10,
    transform: "scale(1)",
    top: theme.spacing(5),
    width: 170,
    zIndex: 3,
}))

export const LayerWrapper = styledForMobile(Card)(({ isMobile, theme }) => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 3),
    position: "absolute",
    right: isMobile ? 0 : 10,
    opacity: 1,
    top: theme.spacing(5),
    zIndex: 3,
}))

export const Icon = styled(LayersIcon)(({ theme }) => ({
    marginRight: theme.spacing(2),
}))

export const LabelWrapper = styled("div")({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    width: "100%",
})

export const Wrapper = styled(LabelWrapper)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}))