import { useCallback, useEffect, useMemo, useState } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { isEqual, sortBy } from "lodash"
import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useAlertsDispatchContext } from "@l2r-front/l2r-query"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { STATUS_DONE, STATUS_TODO } from "../../constants/status"
import { useRoadworksStateContext } from "../../contexts/RoadworksContext"
import { useRoadworkUpdate } from "../../hooks/mutations/useRoadworkUpdate"
import { roadworksQueryKeys } from "../../hooks/queries/queryKeys"
import { useRoadworkWithTasks } from "../../hooks/queries/useRoadwork"
import { useTaskTypes } from "../../hooks/queries/useTaskType"
import { sortTasksById } from "../../utils/roadworkUtils"
import { RoadworkDetailsForm } from "./RoadworkDetailsForm"
import { RoadworkDetailsFormError } from "./RoadworkDetailsForm.error"
import { mapRoadworkForForm, useSaveRoadworkTasks } from "./RoadworkDetailsForm.helpers"
import { RoadworkDetailsFormSkeleton } from "./RoadworkDetailsForm.skeleton"

export function RoadworkDetailsFormPost(props) {

    const { className } = props
    const { road, roadworkUuid } = useParams()
    const { data: roadwork, isLoading, isError } = useRoadworkWithTasks(roadworkUuid, road)
    const { currency } = useRoadworksStateContext()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { addBlockingAlert, cancelBlockingAlert } = useAlertsDispatchContext()
    const [orderedTaskIds, setOrderedTaskIds] = useState(null)
    const queryClient = useQueryClient()

    const updateValidatedState = useCallback((newRoadwork) => {
        const roadworkTaskIds = newRoadwork?.tasks.map(task => task.uuid || task)
        setOrderedTaskIds(roadworkTaskIds)

        setValidatedState(previousState => {
            const previousValidatedState = previousState ?? newRoadwork

            const roadworkWithOrderedTask = {
                ...previousValidatedState,
                tasks: sortTasksById(newRoadwork.tasks, roadworkTaskIds),
            }
            return mapRoadworkForForm(roadworkWithOrderedTask)
        })
    }, [])

    const [validatedState, setValidatedState] = useState(null)

    const { saveRoadworkTasks } = useSaveRoadworkTasks(updateValidatedState, {
        successSnackbarMessage: t(I18N_NAMESPACE, "components.roadworkDetailsForm.snackbar.success"),
        errorSnackbarMessage: t(I18N_NAMESPACE, "components.roadworkDetailsForm.snackbar.error"),
    })

    const mutation = useRoadworkUpdate(roadwork, {}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "components.roadworkDetailsForm.snackbar.error"),
        successSnackbarDuration: 10000,
        successSnackbarMessage: t(I18N_NAMESPACE, validatedState?.year <= new Date().getFullYear() ? "components.roadworkDetailsForm.snackbar.success" :
            "components.roadworkDetailsForm.snackbar.yearChanged.success"),
    })

    const {
        data: taskTypes,
        isLoading: isLoadingTaskTypes,
        isError: isErrorTaskTypes,
    } = useTaskTypes()

    const saveRoadwork = useCallback(async (values, otherFields = {}) => {
        await saveRoadworkTasks(values, roadwork)

        const updatedFields = otherFields
        if (validatedState && validatedState?.year !== roadwork?.year) {
            updatedFields.year = validatedState.year
        }

        if (Object.keys(updatedFields).length) {
            mutation.mutate(updatedFields)
        }
    }, [mutation, roadwork, validatedState, saveRoadworkTasks])

    const onSubmit = useCallback(async (values) => {
        await saveRoadwork(values)
    }, [saveRoadwork])

    useEffect(() => {
        if (roadwork && !validatedState) {
            updateValidatedState(roadwork)
        }
    }, [isLoading, orderedTaskIds, validatedState, roadwork, updateValidatedState])

    const hasPendingChange = useMemo(() => {
        if (!roadwork || !validatedState) {
            return false
        }

        const {
            tasks: mappedRoadworkTasks,
            ...mappedRoadwork
        } = mapRoadworkForForm(roadwork)

        const {
            tasks: validatedTasks,
            ...validatedRoadwork
        } = validatedState

        const sortedMappedRoadworkTasks = sortBy(mappedRoadworkTasks, t => t.uuid)
        const sortedValidatedTasks = sortBy(validatedTasks, t => t.uuid)

        return !isEqual(mappedRoadwork, validatedRoadwork) ||
            !isEqual(sortedMappedRoadworkTasks, sortedValidatedTasks)
    }, [roadwork, validatedState])

    const handleStatusChange = useCallback(async () => {
        const statusField = { status: roadwork?.status === STATUS_TODO ? STATUS_DONE : STATUS_TODO }
        if (hasPendingChange) {
            await saveRoadwork(validatedState, statusField)
        } else {
            mutation.mutate(statusField)
        }
    }, [hasPendingChange, mutation, validatedState, roadwork, saveRoadwork])

    const editionCleanup = useCallback(() => {
        const sectionQueryKey = roadworksQueryKeys.section(roadworkUuid)
        queryClient.setQueryData(sectionQueryKey, oldSection => {
            return ({
                ...oldSection,
                properties: {
                    ...oldSection.properties,
                    technique: roadwork.technique,
                },
            })
        })
    }, [roadwork, roadworkUuid, queryClient])

    useEffect(() => {
        if (hasPendingChange) {
            return addBlockingAlert({
                alertId: "blocking-alert-editing-roadwork-task",
                alertTitle: t(I18N_NAMESPACE, "containers.roadworkTask.blockingAlert.title"),
                cancelText: t(I18N_NAMESPACE, "containers.roadworkTask.blockingAlert.cancelButton"),
                confirmText: t(I18N_NAMESPACE, "containers.roadworkTask.blockingAlert.confirmButton"),
                severity: "warning",
                callback: editionCleanup,
            })
        }
        cancelBlockingAlert("blocking-alert-editing-roadwork-task")
    }, [addBlockingAlert, cancelBlockingAlert, hasPendingChange, editionCleanup, t])


    if (isError || isErrorTaskTypes) {
        return <RoadworkDetailsFormError className={className} />
    }

    if (isLoading || isLoadingTaskTypes || !currency?.symbol || !validatedState) {
        return <RoadworkDetailsFormSkeleton className={className} />
    }

    return <RoadworkDetailsForm
        className={className}
        currency={currency?.symbol}
        hasPendingChange={hasPendingChange}
        onSubmit={onSubmit}
        onStatusChange={handleStatusChange}
        onChange={setValidatedState}
        initialState={validatedState}
        roadwork={roadwork}
        taskTypes={taskTypes}
    />
}

RoadworkDetailsFormPost.propTypes = {
    className: PropTypes.string,
}
