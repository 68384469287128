import { useCallback, useEffect, useMemo } from "react"

import * as turf from "@turf/turf"
import { useParams } from "react-router-dom"

import { useGeoServerFeaturesList } from "@l2r-front/l2r-geodata"
import { ReactMapGl, Map as L2rMap, useMapDispatchContext, STABILO_MAP_ID } from "@l2r-front/l2r-map"
import { useNetworksStateContext, CANVAS_MIN_BBOX_WIDTH } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"

export function StabiloMap(props) {

    const {
        children,
        ...mapProps
    } = props

    const { selectedNetwork } = useNetworksStateContext()
    const { road } = useParams()
    const { getMapRef, setMapBoundingBoxToFit } = useMapDispatchContext()
    const mapRef = getMapRef(STABILO_MAP_ID)

    const { data: roadSegments, isLoading } = useGeoServerFeaturesList(
        ["networks", "list", { road: road }],
        {
            layer: selectedNetwork?.referential?.layerSection,
            linear_location_road: road,
        },
        {
            enabled: !!road && !!selectedNetwork?.referential,
        })

    const roadBoundingBox = useMemo(() => {
        if (isLoading) {
            return null
        }
        if (!roadSegments || !roadSegments.length) {
            const [minLng, minLat, maxLng, maxLat] = turf.bbox(selectedNetwork.boundingBox)
            return {
                minLng,
                minLat,
                maxLng,
                maxLat,
            }
        }

        const roadFeature = turf.featureCollection(roadSegments)
        const [minLng, minLat, maxLng, maxLat] = turf.bbox(roadFeature)
        const lngOffset = Math.max((CANVAS_MIN_BBOX_WIDTH - (maxLng - minLng)), 0) / 2
        return ({
            minLng: minLng - lngOffset,
            minLat,
            maxLng: maxLng + lngOffset,
            maxLat,
        })
    }, [roadSegments, selectedNetwork, isLoading])

    const onRecenterOnNetworkBoundingBox = useCallback(() => {
        setMapBoundingBoxToFit(roadBoundingBox)
    }, [roadBoundingBox, setMapBoundingBoxToFit])

    useEffect(() => {
        if (roadBoundingBox && mapRef) {
            setMapBoundingBoxToFit(roadBoundingBox)
        }
    }, [mapRef, roadBoundingBox, setMapBoundingBoxToFit])

    const mapInitialViewState = useMemo(() => {
        const [minLng, minLat, maxLng, maxLat] = turf.bbox(selectedNetwork.boundingBox)
        return {
            longitude: (minLng + maxLng) / 2,
            latitude: (minLat + maxLat) / 2,
            zoom: 8,
        }
    }, [selectedNetwork])

    if (isLoading) {
        return null
    }

    return (
        <L2rMap
            dragRotate={false}
            id={STABILO_MAP_ID}
            initialViewState={mapInitialViewState}
            isLoading={false}
            logoPosition="top-right"
            onRecenter={onRecenterOnNetworkBoundingBox}
            {...mapProps}
        >
            {children}
        </L2rMap>
    )
}

StabiloMap.propTypes = {
    ...ReactMapGl.propTypes,
    className: PropTypes.string,
}