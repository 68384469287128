import {
    getLineLayerConfig,
    getInterpolatedLineLayerWidth,
    getInterpolatedSelectableLineLayerWidth,
    LINE_LAYER_SIZE,
    SELECTED_LINE_LAYER_SIZE,
} from "@l2r-front/l2r-map"
import { selectLayerColorOpacity } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../common/constants/i18n"
import { impacts } from "./impacts"

export const ROADWORK_LAYER_WIDTH = LINE_LAYER_SIZE + 2

export const getRoadworkLegendConfig = (t) => {
    return Object.values(impacts).map(legendValue => {
        return {
            color: legendValue.backgroundColor,
            label: t(I18N_NAMESPACE, legendValue.text),
        }
    })
}

const getRoadworksBaseConfig = (referentialColor, selectedRoad = null) => {
    const legendStyles = Object.entries(impacts).map(([impactValue, impactConfig]) => {
        return [
            ["==", ["get", "impact", ["get", "technique", ["properties"]]], parseInt(impactValue)],
            selectLayerColorOpacity(impactConfig.backgroundColor, 0.5, selectedRoad),
        ]
    })

    const impactsValuesArray = Object.values(impacts)
    legendStyles.push(impactsValuesArray[impactsValuesArray.length - 1].backgroundColor)

    return getLineLayerConfig({
        "paint": {
            "line-color": [
                "case",
                ["!=", ["typeof", ["get", "impact", ["get", "technique", ["properties"]]]], "number"],
                selectLayerColorOpacity(referentialColor, referentialColor === "#FFFFFF" ? 0.85 : 0.5, selectedRoad),
            ].concat(legendStyles.flat()),
            "line-width": getInterpolatedSelectableLineLayerWidth(selectedRoad),
            "line-dasharray": [
                "match", ["get", "impact", ["get", "technique", ["properties"]]],
                10,
                ["literal", [1, 1]],
                ["literal", [1, 0]],
            ],
        },
        "layout": {
            "line-sort-key": ["get", "zIndex"],
        },
    })
}

export const getRoadworkTechniquesLayerStyle = (referentialColor, selectedTechnique = null, selectedRoad = null, filters = {}) => {
    const roadworksBaseConfig = getRoadworksBaseConfig(referentialColor, selectedRoad)

    const filteredYears = filters.years
    const filteredTechniques = filters.techniques
    const filteredStatuses = filters.statuses

    const [selectedTechniqueCode, selectedTechniqueYear, selectedTechniqueStatus] = selectedTechnique?.split("-") ?? [null]
    const yearFilter = selectedTechniqueYear ? [parseInt(selectedTechniqueYear)] : filteredYears
    const statusFilter = selectedTechniqueStatus ? [parseInt(selectedTechniqueStatus)] : filteredStatuses
    const techniqueFilter = selectedTechniqueCode ? [selectedTechniqueCode] : filteredTechniques

    return {
        ...roadworksBaseConfig,
        "filter": [
            "all",
            yearFilter?.length && ["in", ["get", "year"], ["literal", yearFilter]],
            statusFilter?.length && ["in", ["get", "status"], ["literal", statusFilter]],
            techniqueFilter?.length && ["in", ["get", "code", ["get", "technique", ["properties"]]], ["literal", techniqueFilter]],
        ].filter(Boolean),
    }
}

export const getRoadworksLayerStyle = (referentialColor, selectedRoadwork, selectedRoad = null, filters = {}) => {
    const roadworksBaseConfig = getRoadworksBaseConfig(referentialColor, selectedRoad)
    const filteredYears = filters.years
    const filteredTechniques = filters.techniques

    return {
        ...roadworksBaseConfig,
        "filter": [
            "all",
            filteredYears?.length && ["in", ["get", "year"], ["literal", filteredYears]],
            filteredTechniques?.length && ["in", ["get", "code", ["get", "technique", ["properties"]]], ["literal", filteredTechniques]],
            selectedRoadwork && ["==", ["get", "uuid", ["properties"]], selectedRoadwork],
        ].filter(Boolean),
    }
}

export const getLineLayerStyled = (color) => {

    return getLineLayerConfig({
        "paint": {
            "line-color": `${color}`,
            "line-width": getInterpolatedLineLayerWidth(SELECTED_LINE_LAYER_SIZE),
        },
    })
}