import { useTranslation } from "@l2r-front/l2r-i18n"
import { Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import * as Styled from "./ImageViewerButton.styled"

export const ImageViewerButton = (props) => {

    const {
        id,
        onClick,
    } = props
    const { t } = useTranslation(I18N_NAMESPACE)

    return <Styled.Button
        id={id}
        color="cta-bg/cta-bg-primary"
        onClick={onClick}
        variant="contained"
    >
        <Typography variant="Regular">
            {t(I18N_NAMESPACE, "components.streetViewButton.title")}
        </Typography>
        <Styled.Icon />
    </Styled.Button>
}