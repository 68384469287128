import { FunctionsRoundedIcon, RoadStructureIcon, RoadSurfaceIcon } from "@l2r-front/l2r-icons"

import { NETWORK_GRADING_PREFIX_PARAM } from "../../../common/constants/urlParams"

export const networkGradeTypesConfig = {
    gradeGlobal: {
        icon: FunctionsRoundedIcon,
        label: "containers.roadwaySidebar.notations.global.label",
        tooltipText: "containers.roadwaySidebar.notations.global.tooltip",
        detailTooltipText: "containers.roadwaySidebar.notations.global.tooltip",
    },
    gradeStructure: {
        icon: RoadStructureIcon,
        label: "containers.roadwaySidebar.notations.structure.label",
        tooltipText: "containers.roadwaySidebar.notations.structure.tooltip",
        detailTooltipText: "containers.roadwaySidebar.notations.structure.detailTooltip",
    },
    gradeSurface: {
        icon: RoadSurfaceIcon,
        label: "containers.roadwaySidebar.notations.surface.label",
        tooltipText: "containers.roadwaySidebar.notations.surface.tooltip",
        detailTooltipText: "containers.roadwaySidebar.notations.surface.detailTooltip",
    },
}

export const GLOBAL_GRADE_TYPE = "gradeGlobal"
export const STRUCTURE_GRADE_TYPE = "gradeStructure"
export const SURFACE_GRADE_TYPE = "gradeSurface"
export const GRADES_TYPES = [GLOBAL_GRADE_TYPE, STRUCTURE_GRADE_TYPE, SURFACE_GRADE_TYPE].map(gradeType => `${NETWORK_GRADING_PREFIX_PARAM}${gradeType}`)