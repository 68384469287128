import { useMemo } from "react"

import { useLocation, useMatch, useNavigate } from "react-router-dom"
import urlJoin from "url-join"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useGetActiveModules } from "../../hooks/useGetActiveModules"
import * as Styled from "./MobileModulesSelector.styled"

export const MobileModulesSelector = (props) => {

    const {
        close,
    } = props

    const { t } = useTranslation()
    const { selectedNetwork } = useNetworksStateContext()
    const { storeMapBoundingBox } = useMapDispatchContext()
    const match = useMatch("/:selectedNetwork/:app/:module/*")
    const selectedApp = match?.params?.app
    const selectedModule = match?.params?.module
    const navigate = useNavigate()
    const location = useLocation()
    const appsWithActiveModules = useGetActiveModules()

    const modules = useMemo(() => Object.values(appsWithActiveModules).map(app => {
        const appModules = Object.values(app.modules).map(module => {
            const modulePath = urlJoin("/", app.path ?? "", module.path)
            const Icon = module.icon
            const selected = module.id === "DASHBOARD" ? !selectedApp : selectedModule === module.path
            return <Styled.Button
                color="objects/object-black"
                disabled={module.disabled}
                key={`module-${module.id}`}
                selected={selected}
                onClick={() => {
                    storeMapBoundingBox()
                    const isModuleFromSameApp = app.path === selectedApp
                    const previousPath = (isModuleFromSameApp && match?.params?.["*"].length) ? match?.params?.["*"] : ""
                    storeMapBoundingBox()
                    navigate(`/${selectedNetwork.slug}${modulePath}/${previousPath}${location?.search}`)
                    close()
                }}
                id={`module-${module.id}`}>
                {module.icon ? <Styled.IconWrapper>
                    <Icon color={app.color} />
                </Styled.IconWrapper> : <Styled.ColorIcon color={app.color} />}
                <Styled.OptionText>
                    {t(app.i18nNamespace, module.label)}
                </Styled.OptionText>
            </Styled.Button>
        })

        return [
            app.label && <Styled.ListSubheader
                key={app.label}
                variant="Regular"
            >
                {t(app.i18nNamespace, app.label)}
            </Styled.ListSubheader>,
            appModules,
        ]
    }), [close,
        location?.search,
        match?.params,
        selectedApp,
        selectedModule,
        storeMapBoundingBox,
        selectedNetwork,
        navigate,
        t,
        appsWithActiveModules])

    return <Styled.Wrapper id="modules-list-container">
        {modules}
    </Styled.Wrapper>
}

MobileModulesSelector.propTypes = {
    close: PropTypes.func.isRequired,
}

