import { CloseIcon as Close } from "@l2r-front/l2r-icons"
import {
    Button as UiButton,
    IconButton as UiIconButton,
    CustomSelector,
    Paper,
    styledForMobile,
    styled,
} from "@l2r-front/l2r-ui"

import { MAP_INDICATOR_PADDING } from "../../constants/map"

const TITLE_HEIGHT = 45
const MAX_HEIGHT_DESKTOP = 450
const MAX_HEIGHT_MOBILE = 250
const BOTTOM = 118

export const Button = styledForMobile(UiButton)(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    borderRadius: theme.spacing(2),
    bottom: BOTTOM,
    left: MAP_INDICATOR_PADDING,
    height: "auto",
    minWidth: 0,
    padding: theme.spacing(2),
    position: "absolute",
    textTransform: "capitalize",
    width: "auto",
    zIndex: theme.zIndex.drawer + 2,

    "&:hover": {
        backgroundColor: theme.palette["objects/object-lightgrey"].main,
    },
}))

export const LegendWrapper = styledForMobile(Paper)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    bottom: BOTTOM,
    left: theme.spacing(4),
    padding: theme.spacing(MAP_INDICATOR_PADDING, 3, 6, 6),
    position: "absolute",
    width: "auto",
    zIndex: theme.zIndex.drawer + 2,
}))

export const OverflowWrapper = styled("div")(({ isMobile, theme }) => ({
    maxHeight: isMobile ? MAX_HEIGHT_MOBILE : MAX_HEIGHT_DESKTOP,
    height: `calc(100% - ${TITLE_HEIGHT}px)`,
    paddingRight: theme.spacing(3),
    overflowY: "auto",
}))

export const Color = styled("div")(({ color, theme }) => ({
    backgroundColor: color,
    borderRadius: theme.spacing(1),
    height: 24,
    marginRight: theme.spacing(6),
    width: 24,
}))

export const ItemWrapper = styled("div")(({ last, theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    margin: theme.spacing(3, 0),
    marginBottom: last && 0,
}))

export const Select = styled(CustomSelector)({
    margin: 0,
    width: "100%",
})

export const IconButton = styled(UiIconButton)(({ theme }) => ({
    padding: 0,
    position: "absolute",
    height: 24,
    right: theme.spacing(3),
    top: theme.spacing(3),
    width: 24,
}))

export const CloseIcon = styled(Close)({
    height: 16,
    width: 16,
})

export const TitleWrapper = styled("div")({
    alignItems: "center",
    display: "flex",
    width: "100%",
})

export const LabelWrapper = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
})