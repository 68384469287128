import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme } from "@l2r-front/l2r-ui"
import { getContrastTextColor } from "@l2r-front/l2r-utils"

import { STATUS_DONE, STATUS_TODO } from "../../constants/status"
import * as Styled from "./RoadworkQuarterBudget.styled"

export function RoadworkQuarterBudget(props) {

    const {
        className,
        cost,
        currency,
        id,
        label,
        status,
        width,
    } = props

    const theme = useTheme()

    const backgroundColor = status === STATUS_DONE ? "objects/object-primary"
        : "surfaces/surface-clair"

    const textColor = status === STATUS_DONE ? theme.palette["texts/text-invert"].main
        : getContrastTextColor(theme.palette["texts/text-invert"].main)

    return <Styled.QuarterBudgetWrapper className={className} id={id} status={status} width={width}>
        {label && <Styled.Label variant="Small">{label}</Styled.Label>}
        <Styled.QuarterBudget backgroundColor={backgroundColor} status={status}>
            <Styled.Text color={textColor} variant="H4">{cost}{currency}</Styled.Text>
        </Styled.QuarterBudget>
    </Styled.QuarterBudgetWrapper>
}

RoadworkQuarterBudget.propTypes = {
    className: PropTypes.string,
    cost: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    status: PropTypes.oneOf([STATUS_DONE, STATUS_TODO]).isRequired,
    width: PropTypes.number.isRequired,
}