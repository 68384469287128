import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpApi from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

const loadPathUrl = (process.env["NX_ENVIRONMENT"] === "prod") ?
    new URL("https://lcstrad.z16.web.core.windows.net/{{lng}}/{{ns}}.json", process.env.NX_FRONT_L2R) :
    new URL("/assets/locales/{{lng}}/{{ns}}.json", process.env.NX_FRONT_L2R)

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        debug: process.env.NODE_ENV === "development",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        ns: ["common", "l2r-services", "l2r-app-road", "l2r-query", "l2r-app-svrai", "l2r-networks", "l2r-map", "l2r-ui"],
        resources: {},
        partialBundledLanguages: true,
        backend: {
            loadPath: decodeURI(loadPathUrl.href),
        },
        detection: {
            order: ["navigator"],
        },
        react: {
            useSuspense: true,
        },
    })

export { i18n }
