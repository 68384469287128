import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { appRoad } from "../../../../common/constants/appRoad"
import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { RoadworksBudgetsKpiComponent } from "../../components/RoadworksBudgetsKpi"
import { STATUS_DONE, STATUS_TODO } from "../../constants/status"
import { useRoadworksProject } from "../../hooks/queries/useRoadworksProjectQuery"
import { useYearsStatsQuery } from "../../hooks/queries/useYearsStatsQuery"
import * as Styled from "./RoadworksBudgetsKpi.styled"


export function RoadworksBudgetsKpi(props) {

    const { data, isLoading: isLoadingYearsStats, isError } = useYearsStatsQuery()
    const { t } = useTranslation()
    const isMobile = useIsMobileDevice()
    const { data: project, isLoading: isLoadingProject } = useRoadworksProject()

    const costDataLength = Object.entries(data?.cost || {})?.length
    const isLoading = isLoadingYearsStats || isLoadingProject

    const formattedData = useMemo(() => {
        if (costDataLength) {
            return Object.entries(data.cost).map(([key, value]) => {
                const doneCost = parseFloat(value[STATUS_DONE])
                const plannedCost = parseFloat(value[STATUS_TODO]) + doneCost
                return ({
                    date: parseInt(key),
                    done: doneCost,
                    planned: plannedCost,
                })
            })
        }
        return null
    }, [costDataLength, data])

    if (isError || (!costDataLength && !isLoading) || (!formattedData && !isLoading)) {
        return <Styled.KpiError
            {...props}
            title={t(I18N_NAMESPACE, "containers.notationKpi.error")}
            id="roadworks-budgets-kpi-error"
            message={t(I18N_NAMESPACE, "containers.notationKpi.error")}
            path={`${appRoad.path}/${appRoad.modules.roadworks.path}`}
        />
    }

    if (isLoading) {
        return <Styled.KpiSkeleton {...props} />
    }

    return <Styled.KpiCard
        id="roadworks-budgets-kpi"
        path={`${appRoad.path}/${appRoad.modules.roadworks.path}`}
        {...props}
    >
        <Styled.Title isMobile={isMobile} variant="H1">
            {t(I18N_NAMESPACE, "components.roadworksBudgetsKpi.title")}
        </Styled.Title>
        <Styled.ChartContainer>
            <RoadworksBudgetsKpiComponent currency={project?.currency?.symbol} data={formattedData} />
        </Styled.ChartContainer>
    </Styled.KpiCard >
}