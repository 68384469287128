import { useEffect } from "react"

import { useParams } from "react-router-dom"

import { useGeoServerFeaturesList } from "@l2r-front/l2r-geodata"
import { useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import {
    networksQueryKeys,
    useNetworksStateContext,
} from "@l2r-front/l2r-networks"

import { useRoadworksDispatchContext, useRoadworksStateContext } from "../../contexts/RoadworksContext"

export function RoadworksDetailSegmentSelectListener() {

    const { selectedSegmentUuid } = useRoadworksStateContext()
    const { setSelectedSegment } = useRoadworksDispatchContext()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()
    const { selectedNetwork } = useNetworksStateContext()

    const { road } = useParams()
    const queryKeyParams = {
        layer: selectedNetwork?.referential?.layerSection,
        linear_location_road: road,
    }

    const { data: selectedNetworkReferentialFeatures } = useGeoServerFeaturesList(
        networksQueryKeys.features(queryKeyParams),
        queryKeyParams,
        {
            enabled: !!(road && selectedNetwork?.referential?.layerSection),
        })

    useEffect(() => {
        const segmentFeature = selectedNetworkReferentialFeatures?.[0]
        if (!selectedSegmentUuid && !!segmentFeature) {
            setSegmentFeatureIdClicked(segmentFeature.uuid)
            setSelectedSegment(segmentFeature)
        }
    }, [selectedNetworkReferentialFeatures, selectedSegmentUuid, setSelectedSegment, setSegmentFeatureIdClicked])
}