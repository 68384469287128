import {
    MapIcon,
    SatelliteAltIcon,
} from "@l2r-front/l2r-icons"

import { default as planBackground } from "../assets/planBackground.png"
import { default as satelliteBackground } from "../assets/satelliteBackground.png"

export const MapStyles = {
    plan: {
        background: satelliteBackground,
        color: "objects/object-lightgrey",
        contrastColor: "objects/object-black",
        icon: SatelliteAltIcon,
        mapStyle: "mapbox://styles/jteyssandier/clav74muv004r14n9iowbjqng",
    },
    satellite: {
        background: planBackground,
        color: "objects/object-black",
        contrastColor: "surfaces/surface-primary",
        icon: MapIcon,
        mapStyle: "mapbox://styles/jteyssandier/clb2e2obn000c15njpiw2suvr",
    },
}