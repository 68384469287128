import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography } from "@l2r-front/l2r-ui"

import { RoadAssesmentList } from "../../../../common/components/RoadAssesmentList"
import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { DAMAGES_URL_PREFIX_PARAM } from "../../../../common/constants/urlParams"
import { DamagesStat } from "../../components/DamagesStat"
import { damagesCauseConfig } from "../../constants/damagesCauseConfig"
import { useRoadwayDispatchContext, useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { useNetworkConditionForYearlyStatus } from "../../hooks/queries/networkConditions/useNetworkConditionForYearlyStatus"
import * as Styled from "./DamagesList.styled"

export function DamagesList(props) {

    const {
        damagesToDisplay,
        isLoading,
        isError,
        noDamageTextKey,
    } = props

    const {
        selectedNetworkYearlyStatus,
        selectedNetworkAssesmentType,
    } = useRoadwayStateContext()

    const {
        isLoading: isLoadingNetworkCondition,
        isError: isErrorNetworkCondition,
        networkCondition,
    } = useNetworkConditionForYearlyStatus(selectedNetworkYearlyStatus)

    const { setSelectedNetworkAssesmentType } = useRoadwayDispatchContext()
    const { t } = useTranslation(I18N_NAMESPACE)

    const filteredDamagesList = useMemo(() => {
        return (networkCondition?.damageTypes || []).filter(damageType => !damagesToDisplay || (damagesToDisplay || []).some(damageToDisplay => {
            return damageToDisplay.damageCode === damageType.code
        })).map(damageType => {
            const damage = (damagesToDisplay || []).find(damageToDisplay => {
                return damageToDisplay.damageCode === damageType.code
            })
            return ({
                ...damageType,
                damageStat: damage?.damageStat,
            })
        })
            .sort((a, b) => a.name.localeCompare(b.name))
    }, [networkCondition?.damageTypes, damagesToDisplay])

    const damagesItems = useMemo(() => {
        if (isLoading || isLoadingNetworkCondition) {
            return [<Styled.RoadInfoItemSkeleton key="roadInfo-loading" />]
        }

        if (isError || isErrorNetworkCondition) {
            return [<Styled.ErrorText key="roadInfo-error">{t(I18N_NAMESPACE, "containers.roadwaySidebar.damages.error")}</Styled.ErrorText>]
        }

        return filteredDamagesList.map(damageType => {
            return (
                <Styled.RoadInfoItem
                    id={`damage-list-item-${damageType.code}`}
                    key={damageType.code}
                    label={damageType.name}
                    icon={damagesCauseConfig?.[damageType.cause].icon}
                    onClick={() => setSelectedNetworkAssesmentType(`${DAMAGES_URL_PREFIX_PARAM}${damageType.code}`)}
                    selected={selectedNetworkAssesmentType === `${DAMAGES_URL_PREFIX_PARAM}${damageType.code}`}
                >
                    <DamagesStat
                        damageType={damageType}
                    />
                </Styled.RoadInfoItem>
            )
        })
    }, [filteredDamagesList,
        selectedNetworkAssesmentType,
        setSelectedNetworkAssesmentType,
        isLoading,
        isError,
        isLoadingNetworkCondition,
        isErrorNetworkCondition,
        t])

    return <RoadAssesmentList listKey="damages" title={t(I18N_NAMESPACE, "containers.roadwaySidebar.damages.title")}>
        {
            damagesItems.length ? damagesItems :
                selectedNetworkYearlyStatus?.isSimulated ? (
                    <Typography id="no-damage-warning">
                        {t(I18N_NAMESPACE, "containers.roadwaySidebar.damages.noDamageForSimulation")}
                    </Typography>
                ) : (
                    <Typography id="no-damage-warning">
                        {t(I18N_NAMESPACE, noDamageTextKey)}
                    </Typography>
                )
        }
    </RoadAssesmentList>
}

DamagesList.propTypes = {
    damagesToDisplay: PropTypes.arrayOf(PropTypes.shape({
        damageCode: PropTypes.string.isRequired,
        damageStat: PropTypes.number.isRequired,
    })),
    isSegment: PropTypes.bool,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    noDamageTextKey: PropTypes.string.isRequired,
}

DamagesList.defaultProps = {
    damagesToDisplay: [],
}