import { client } from "@l2r-front/l2r-query"

import { useSvraiQuery } from "../../../../../common/hooks/queries/useSvraiQuery"
import { eventsQueryKeys } from "./queryKeys"

function initialData(
    uuid,
) {
    const queries = client.getQueriesData(eventsQueryKeys["list"]())
    for (const [_, queryData] of queries) {
        if (!queryData) {
            continue
        }

        const event = queryData.results.find((result) => {
            return result.uuid === uuid
        })
        if (event) {
            return event
        }
    }

    return undefined
}

export function useEventQuery(
    uuid,
    queryParameters,
    config = {},
) {
    const url = `events/${uuid}/`
    const { enabled = true } = config

    return useSvraiQuery(
        url,
        eventsQueryKeys["detail"](uuid),
        queryParameters,
        {
            config,
            enabled: !!uuid && enabled,
        },
        initialData(uuid),
    )
}
