import { useTheme } from "@emotion/react"
import { useSearchParams } from "react-router-dom"

import { useMediaQuery } from "./useMediaQuery"


export function useIsMobileDevice() {
    const theme = useTheme()
    const [searchParams] = useSearchParams()
    const forcedDevice = searchParams.get("device")

    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"))
    const isMobileDevice = !isLargeScreen

    return (isMobileDevice && forcedDevice !== "desktop") || forcedDevice === "mobile"
}