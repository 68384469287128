import * as turf from "@turf/turf"

import { getPreviousSegmentsTotalLength } from "@l2r-front/l2r-geodata"

const BRUSH_PIXEL_RADIUS = 28

export function segmentInsideCircle(segmentStart, segmentEnd, circleCenter, circleRadius) {
    const startInside = isPointInsideCircle(segmentStart, circleCenter, circleRadius)
    const endInside = isPointInsideCircle(segmentEnd, circleCenter, circleRadius)

    if (startInside && endInside) {
        return [0, 1]
    }

    const intersectionPoints = findIntersectionPoints(segmentStart, segmentEnd, circleCenter, circleRadius)

    if (intersectionPoints.length === 0) {
        return []
    }

    const insideSegment = []

    if (startInside) {
        insideSegment.push(0)
    }

    insideSegment.push(...intersectionPoints)

    if (endInside) {
        insideSegment.push(1)
    }

    if (insideSegment.length >= 2) {
        return [insideSegment[0], insideSegment[insideSegment.length - 1]].sort((a, b) => a - b)
    } else {
        return []
    }
}

function isPointInsideCircle(point, circleCenter, circleRadius) {
    const distanceSquared = Math.pow(point[0] - circleCenter[0], 2) + Math.pow(point[1] - circleCenter[1], 2)
    return distanceSquared <= Math.pow(circleRadius, 2)
}

function findIntersectionPoints(segmentStart, segmentEnd, circleCenter, circleRadius) {
    const dx = segmentEnd[0] - segmentStart[0]
    const dy = segmentEnd[1] - segmentStart[1]

    const a = dx * dx + dy * dy
    const b = 2 * (dx * (segmentStart[0] - circleCenter[0]) + dy * (segmentStart[1] - circleCenter[1]))
    const c = Math.pow(segmentStart[0] - circleCenter[0], 2) + Math.pow(segmentStart[1] - circleCenter[1], 2) - Math.pow(circleRadius, 2)

    const discriminant = b * b - 4 * a * c

    const parametricSolutions = []

    if (discriminant > 0) {
        const t1 = (-b + Math.sqrt(discriminant)) / (2 * a)
        const t2 = (-b - Math.sqrt(discriminant)) / (2 * a)

        if (t1 >= 0 && t1 <= 1) {
            parametricSolutions.push(t1)
        }
        if (t2 >= 0 && t2 <= 1) {
            parametricSolutions.push(t2)
        }
    }

    return parametricSolutions
}

export function initStateFromFeatures(state, features) {
    features.forEach(feature => {
        const existingParametricSegment = state.parametricSegments.find(p => feature.properties.featureIndex === p.featureIndex
            && feature.properties.segmentIndex === p.segmentIndex)
        if (existingParametricSegment) {
            existingParametricSegment.parametrics.push([feature.properties.start, feature.properties.end])
        } else {
            state.parametricSegments.push(
                {
                    featureIndex: feature.properties.featureIndex,
                    segmentIndex: feature.properties.segmentIndex,
                    parametrics: [
                        [feature.properties.start, feature.properties.end],
                    ],
                },
            )
        }

        state.featureIds.push(feature.id)
    })
}

function mergeParametricsRanges(parametricsRanges, rangeToAdd) {
    parametricsRanges.push(rangeToAdd)

    parametricsRanges.sort((a, b) => a[0] - b[0])

    const mergedParametrics = [parametricsRanges[0]]

    for (let i = 1; i < parametricsRanges.length; i++) {
        const currentParametric = parametricsRanges[i]
        const lastMergedParametric = mergedParametrics[mergedParametrics.length - 1]

        if (currentParametric[0] <= lastMergedParametric[1]) {
            lastMergedParametric[1] = Math.max(lastMergedParametric[1], currentParametric[1])
        } else {
            mergedParametrics.push(currentParametric)
        }
    }

    return mergedParametrics
}

function cutParametricsRanges(parametricsRanges, cuttingRange) {
    const cuttedParametrics = []

    for (const currentParametric of parametricsRanges) {
        if ((cuttingRange[0] <= currentParametric[0]) && (cuttingRange[1] >= currentParametric[1])) {
            continue
        } else if ((cuttingRange[0] <= currentParametric[0]) && (cuttingRange[1] >= currentParametric[0])) {
            cuttedParametrics.push([cuttingRange[1], currentParametric[1]])
        } else if ((cuttingRange[0] <= currentParametric[1]) && (cuttingRange[1] >= currentParametric[1])) {
            cuttedParametrics.push([currentParametric[0], cuttingRange[0]])
        } else if ((cuttingRange[0] > currentParametric[0]) && (cuttingRange[1] < currentParametric[1])) {
            cuttedParametrics.push([currentParametric[0], cuttingRange[0]])
            cuttedParametrics.push([cuttingRange[1], currentParametric[1]])
        } else {
            cuttedParametrics.push(currentParametric)
        }
    }

    return cuttedParametrics.filter(p => (p.length === 2) && (p[0] !== p[1]))
}

export function addParametrics(parametrics, currentParametrics) {
    parametrics.forEach(param => {
        const currentSegmentParam = currentParametrics.find(p => p.featureIndex === param.featureIndex && p.segmentIndex === param.segmentIndex)
        if (!currentSegmentParam) {
            param.parametrics = [param.parametrics]
            currentParametrics.push(param)
        } else {
            currentSegmentParam.parametrics = mergeParametricsRanges(currentSegmentParam.parametrics, param.parametrics)
        }
    })


    return currentParametrics
}

export function substractParametrics(parametrics, currentParametrics) {
    parametrics.forEach(param => {
        const currentSegmentParamIndex = currentParametrics.findIndex(p => p.featureIndex === param.featureIndex && p.segmentIndex === param.segmentIndex)
        const currentSegmentParam = currentParametrics[currentSegmentParamIndex]
        if (currentSegmentParam) {
            const cuttedParametrics = cutParametricsRanges(currentSegmentParam.parametrics, param.parametrics)
            if (cuttedParametrics.length) {
                currentSegmentParam.parametrics = cuttedParametrics
            } else {
                currentParametrics.splice(currentSegmentParamIndex, 1)
            }
        }
    })

    return currentParametrics
}

export function createParametrics(mode, state, paintedParametrics, addition = true) {
    let featuresUpdated = false

    const newParametrics = addition ? addParametrics(paintedParametrics, state.parametricSegments) :
        substractParametrics(paintedParametrics, state.parametricSegments)

    state.parametricSegments = newParametrics

    var parametricsToCreate = []
    var featuresToKeep = []

    newParametrics.forEach(parametric => {
        parametric.parametrics.forEach(p => {
            const matchingFeatureId = state.featureIds.find(featureId => {
                const feature = mode.getFeature(featureId)

                return (feature && feature.properties.featureIndex === parametric.featureIndex
                    && feature.properties.segmentIndex === parametric.segmentIndex
                    && feature.properties.start === p[0]
                    && feature.properties.end === p[1])
            })

            if (matchingFeatureId) {
                featuresToKeep.push(matchingFeatureId)
            } else {
                parametricsToCreate.push({
                    ...parametric,
                    parametrics: [p],
                })
            }
        })
    })

    state.featureIds.forEach(featureId => {
        if (!featuresToKeep.includes(featureId)) {
            mode.deleteFeature(featureId)
            featuresUpdated = true
        }
    })
    state.featureIds = [...featuresToKeep]

    parametricsToCreate.forEach(ps => {
        const feature = state.roadSegments.features[ps.featureIndex]
        const featureSegmentStart = feature.geometry.coordinates[ps.segmentIndex]
        const featureSegmentEnd = feature.geometry.coordinates[ps.segmentIndex + 1]
        const dx = featureSegmentEnd[0] - featureSegmentStart[0]
        const dy = featureSegmentEnd[1] - featureSegmentStart[1]
        const geoJSONFeatures = ps.parametrics.map(p => {
            const coordinates = [
                [
                    featureSegmentStart[0] + p[0] * dx,
                    featureSegmentStart[1] + p[0] * dy,
                ],
                [
                    featureSegmentStart[0] + p[1] * dx,
                    featureSegmentStart[1] + p[1] * dy,
                ],
            ]
            return turf.lineString(
                coordinates,
                {
                    featureIndex: ps.featureIndex,
                    segmentIndex: ps.segmentIndex,
                    start: p[0],
                    end: p[1],
                },
            )
        })

        geoJSONFeatures.forEach(geoJSONFeature => {
            const drawFeature = mode.newFeature(geoJSONFeature)
            mode.addFeature(drawFeature)
            state.featureIds.push(drawFeature.id)
            featuresUpdated = true
        })

    })

    return featuresUpdated
}

export function initStateFromRoadworkFeatures(state) {
    state.parametricSegments = []
    state.featureIds = []

    const paintedParametrics = []
    turf.segmentEach(state.roadSegments, (currentSegment, featureIndex, multiFeatureIndex, geometryIndex, segmentIndex) => {
        const roadFeature = state.roadSegments.features[featureIndex]
        const roadLength = turf.length(roadFeature)
        const segmentRelativeStart = getPreviousSegmentsTotalLength(roadFeature, segmentIndex) / roadLength
        const segmentRelativeEnd = segmentRelativeStart + turf.length(currentSegment) / roadLength
        const currentSegmentLinearLocation = {
            start: roadFeature.linear_location[0].start + segmentRelativeStart * (roadFeature.linear_location[0].end - roadFeature.linear_location[0].start),
            end: roadFeature.linear_location[0].start + segmentRelativeEnd * (roadFeature.linear_location[0].end - roadFeature.linear_location[0].start),
        }
        for (const roadworkLinearLocation of state.startingFeatures.linear_location) {
            const minLinearRange = (roadworkLinearLocation.start < currentSegmentLinearLocation.start) ? roadworkLinearLocation : currentSegmentLinearLocation
            const maxLinearRange = (minLinearRange === roadworkLinearLocation) ? currentSegmentLinearLocation : roadworkLinearLocation

            if (minLinearRange.end >= maxLinearRange.start) {
                const linearIntersection = {
                    start: maxLinearRange.start,
                    end: (minLinearRange.end < maxLinearRange.end ? minLinearRange.end : maxLinearRange.end),
                }
                paintedParametrics.push({
                    featureIndex,
                    multiFeatureIndex,
                    geometryIndex,
                    segmentIndex,
                    parametrics: [
                        (linearIntersection.start - currentSegmentLinearLocation.start) / (currentSegmentLinearLocation.end - currentSegmentLinearLocation.start),
                        (linearIntersection.end - currentSegmentLinearLocation.start) / (currentSegmentLinearLocation.end - currentSegmentLinearLocation.start),
                    ],
                })
            }
        }
    })

    return paintedParametrics
}

export function computeBrushRadiusAtMousePosition(mode, mouseEvent) {
    const pixelCoords = mouseEvent.point
    const brushBorderPixelCoords = {
        x: pixelCoords.x + BRUSH_PIXEL_RADIUS,
        y: pixelCoords.y,
    }
    const brushBorderCoord = mode.map.unproject(brushBorderPixelCoords)
    const brushRadius = Math.abs(brushBorderCoord.lng - mouseEvent.lngLat.lng)

    return brushRadius
}

export function computeBrushParametrics(state, brushRadius, mouseEvent) {
    const paintedParametrics = []
    turf.segmentEach(state.roadSegments, (currentSegment, featureIndex, multiFeatureIndex, geometryIndex, segmentIndex) => {
        const segmentPoints = currentSegment.geometry.coordinates
        const intersectedParametrics = segmentInsideCircle(segmentPoints[0], segmentPoints[1], [mouseEvent.lngLat.lng, mouseEvent.lngLat.lat], brushRadius)
        if (intersectedParametrics.length) {
            paintedParametrics.push({
                featureIndex,
                multiFeatureIndex,
                geometryIndex,
                segmentIndex,
                parametrics: intersectedParametrics,
            })
        }
    })

    return paintedParametrics
}