import { useQueryClient } from "@tanstack/react-query"

import { useAuthMutation } from "@l2r-front/l2r-auth"
import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

export function useChangeTermsAccepted(config = {}) {
    const queryClient = useQueryClient()

    const url = "me/"
    return useAuthMutation(
        url,
        axiosInstance.patch,
        {},
        {
            ...config,
            onSuccess: () => {
                queryClient.invalidateQueries(["user"])
                queryClient.refetchQueries(["user"])
            },
        })
}
