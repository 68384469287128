import { useCallback, useMemo, useState } from "react"

import { useMatch, useSearchParams } from "react-router-dom"

import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { RoadwayStreetViewDispatchContext, RoadwayStreetViewStateContext } from "./RoadwayStreetViewContext.context"

export const RoadwayStreetViewContextProvider = (props) => {
    const { children } = props
    const [searchParams] = useSearchParams()
    const { selectedNetwork } = useNetworksStateContext()
    const isMobile = useIsMobileDevice()
    const match = useMatch(":slug/road/:moduleId/:road")

    const [roadStreetViewState, _] = useState()

    const onOpenStreetView = useCallback(() => {
        const params = "popup,width=700,height=800,left=100,top=100"
        const road = match?.params.road
        window.open(
            `/${selectedNetwork.slug}/road/roadway/streetView?device=${isMobile ? "mobile" : "desktop"}&${searchParams}&road=${road}`,
            "analysis",
            params)
    }, [isMobile, searchParams, selectedNetwork, match])

    const dispatchValue = useMemo(() => {
        return {
            onOpenStreetView: match?.params.road ? onOpenStreetView : null,
        }
    }, [match?.params.road, onOpenStreetView])

    return (
        <RoadwayStreetViewStateContext.Provider value={roadStreetViewState}>
            <RoadwayStreetViewDispatchContext.Provider value={dispatchValue}>
                {children}
            </RoadwayStreetViewDispatchContext.Provider>
        </RoadwayStreetViewStateContext.Provider>
    )
}

RoadwayStreetViewContextProvider.propTypes = {
    children: PropTypes.node,
}