import { useCallback } from "react"

import { useNavigate, useSearchParams } from "react-router-dom"

export function useNavigateWithSearchParams() {

    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const existingSearchParams = Array.from(searchParams.entries()).some(([_, paramValue]) => paramValue.length)

    const navigateWithSearchParams = useCallback((url) => {
        const urlContainingParams = url.indexOf("?") >= 0
        navigate(existingSearchParams ? `${url}${urlContainingParams ? "&" : "?"}${searchParams}` : url)
    }, [existingSearchParams, searchParams, navigate])

    return navigateWithSearchParams
}