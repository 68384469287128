import React, { useCallback } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { STATUS_DONE } from "../../constants/status"
import * as Styled from "./RoadworksQuartersScale.styled"

const SCALE_GAP = 20

export function RoadworksQuartersScale(props) {

    const {
        budget,
        quartersCost,
        maxPercentage,
    } = props

    const ScaleTicks = useCallback(() => {
        const scaleTicks = Math.ceil(maxPercentage / SCALE_GAP) + 1

        return Array.from({ length: scaleTicks })
            .map((_, index) => {
                const tickNumber = index === scaleTicks - 1 ? maxPercentage
                    : Math.round(index * SCALE_GAP)
                const position = tickNumber / maxPercentage * 100

                return <React.Fragment key={`scale-ticks-${index}`}>
                    <Styled.Tick left={position} />
                    <Styled.TickLabel left={position} variant="Small accent">
                        {`${tickNumber}%`}
                    </Styled.TickLabel>
                </React.Fragment>
            })
    }, [maxPercentage])

    const DoneLabel = useCallback(() => {
        const values = Object.values(quartersCost)[0]
        const totalDoneCost = values[STATUS_DONE].reduce((acc, value) => acc + parseFloat(value), 0)
        const percentageDone = totalDoneCost / budget * 100
        const position = percentageDone / maxPercentage * 100

        return <>
            <Styled.DoneTick left={position} />
            <Styled.DoneLabelWrapper left={position} id="roadworks-quarters-done-label">
                <Styled.DoneText variant="H4">{`${Math.round(percentageDone)}%`}</Styled.DoneText>
            </Styled.DoneLabelWrapper>
        </>
    }, [budget, quartersCost, maxPercentage])

    return <Styled.ScaleWrapper id="roadworks-quarters-scale">
        <ScaleTicks />
        <DoneLabel />
    </Styled.ScaleWrapper>
}

RoadworksQuartersScale.propTypes = {
    maxPercentage: PropTypes.number.isRequired,
    quartersCost: PropTypes.quartersCost.isRequired,
}