import { useEffect, useMemo, useState } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { handleRoundNumber } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { RoadworksList } from "../../components/RoadworksList"
import {
    TechniqueItemCardError,
    TechniqueItemCardSkeleton,
} from "../../components/TechniqueItemCard"
import { STATUS_DONE } from "../../constants/status"
import {
    useRoadworksStateContext,
} from "../../contexts/RoadworksContext"
import { useRoadworks } from "../../hooks/queries/useRoadworks"
import { RoadworkCard } from "../RoadworkCard"
import * as Styled from "./RoadRoadworksList.styled"

export function RoadRoadworksList(props) {

    const {
        className,
    } = props

    const [sortedRoadworksUuids, setSortedRoadworksUuids] = useState([])
    const { road } = useParams()
    const { apiFilters } = useRoadworksStateContext()
    const filtersWithoutStatus = {
        ...apiFilters,
        status: null,
        status__in: [],
    }
    const { data: roadworks, isError, isLoading } = useRoadworks(filtersWithoutStatus, road)
    const { t } = useTranslation(I18N_NAMESPACE)
    const { currency } = useRoadworksStateContext()

    useEffect(() => {
        if (roadworks) {
            if (!areSameRoadworks(roadworks, sortedRoadworksUuids)) {
                const sortedRoadworks = sortRoadworks(roadworks)
                const sortedRoadworksIds = sortedRoadworks.map(roadwork => roadwork.uuid)
                setSortedRoadworksUuids(sortedRoadworksIds)
            }
        }
    }, [roadworks, sortedRoadworksUuids])

    const displayedRoadworks = useMemo(() => {
        if (roadworks?.length && areSameRoadworks(roadworks, sortedRoadworksUuids)) {
            return forceSortRoadworks(roadworks, sortedRoadworksUuids)
        } else if (roadworks?.length) {
            return sortRoadworks(roadworks)
        }
        return null
    }, [roadworks, sortedRoadworksUuids])

    const defaultRoadworksArray = [...Array(4).keys()]

    const totalRoadworksCost = useMemo(() => {
        return roadworks?.reduce((acc, roadwork) => {
            const techniqueCost = handleRoundNumber(roadwork.technique.cost, 0)
            return acc + techniqueCost
        }, 0)
    }, [roadworks])

    if (!isLoading && isError) {
        return <RoadworksList
            className={className}
            roadworksItems={
                defaultRoadworksArray.map(() => <TechniqueItemCardError />)
            }
        />
    }

    if (isLoading || !currency?.symbol) {
        return <RoadworksList
            className={className}
            roadworksItems={
                defaultRoadworksArray.map(() => <TechniqueItemCardSkeleton />)
            }
        />
    }

    if (!displayedRoadworks) {
        return <Styled.Typography id="no-data-message">
            {t(I18N_NAMESPACE, "containers.roadRoadworksList.noData")}
        </Styled.Typography>
    }

    return (
        <RoadworksList
            className={className}
            roadworksItems={
                displayedRoadworks.map((roadwork, index) => {
                    return <RoadworkCard
                        currency={currency}
                        index={index}
                        roadwork={roadwork} />
                })
            }
            totalCost={totalRoadworksCost}
            currency={currency}
        />
    )
}

RoadRoadworksList.propTypes = {
    className: PropTypes.string,
}

function sortRoadworks(roadworks) {
    return roadworks.sort((a, b) => {
        if (a.status !== b.status) {
            if (a.status === STATUS_DONE) {
                return 1
            } else {
                return -1
            }
        }

        if (a.year !== b.year) {
            return a.year - b.year
        }

        if (a.technique.impact !== b.technique.impact) {
            return impactsOrder[b.technique.impact] - impactsOrder[a.technique.impact]
        }
        return b.technique.cost - a.technique.cost
    })
}

function forceSortRoadworks(roadworks, sortedRoadworksUuids) {
    return roadworks.sort((a, b) => {
        const aUuidIndex = sortedRoadworksUuids.indexOf(a.uuid)
        const bUuidIndex = sortedRoadworksUuids.indexOf(b.uuid)
        if (aUuidIndex > bUuidIndex) {
            return 1
        } else {
            return -1
        }
    })
}

function areSameRoadworks(roadworks, roadworksUuids) {
    return roadworks?.length === roadworksUuids?.length && roadworks.every(roadwork => roadworksUuids.includes(roadwork.uuid))
}

const impactsOrder = {
    20: 1,
    40: 2,
    30: 3,
    10: 4,
} 