import { ReactMapGl } from "@l2r-front/l2r-map"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { Map } from "../../../../common/containers/Map"
import * as Styled from "./MobileRoadworksMap.styled"

export function MobileRoadworksMap(props) {
    const {
        children,
        ...mapProps
    } = props

    return (
        <Styled.Container>
            <Map {...mapProps}>
                {children}
            </Map>
        </Styled.Container>
    )
}

MobileRoadworksMap.propTypes = {
    ...ReactMapGl.propTypes,
    className: PropTypes.string,
}