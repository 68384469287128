import React, { useMemo } from "react"

import { Orientation } from "@visx/axis"
import { GridRows } from "@visx/grid"
import { Group } from "@visx/group"
import { scaleBand, scaleLinear } from "@visx/scale"
import { BarGroup } from "@visx/shape"
import { useTooltip } from "@visx/tooltip"
import { Zoom } from "@visx/zoom"
import { maxBy, minBy } from "lodash"
import { useMeasure } from "react-use"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography, useTheme, useIsMobileDevice } from "@l2r-front/l2r-ui"
import { constrainBandwithZoom, rescaleChartBandwidth } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { formatNumberWithUnit } from "../../utils/formatting"
import * as Styled from "./RoadworksBudgetsKpi.styled"

const INITIAL_BAR_PADDING_LEFT = 5
const CHART_PADDING_Y = 40
const CHART_PADDING_X = 70
const CHART_MIN_WIDTH = 500
const TOOLTIP_OFFSET_TOP = 150
const HOVERABLE_PADDING = 10

export function RoadworksBudgetsKpiComponent(props) {

    const {
        className,
        currency,
        data,
    } = props

    const theme = useTheme()
    const [ref, { width, height }] = useMeasure()
    const isMobile = useIsMobileDevice()
    const { t } = useTranslation(I18N_NAMESPACE)
    const statuses = Object.keys(data[0]).filter((d) => d !== "date")

    const {
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip,
    } = useTooltip()

    const margin = {
        top: CHART_PADDING_Y,
        right: CHART_PADDING_X,
        bottom: CHART_PADDING_Y,
        left: CHART_PADDING_X,
    }

    const dateScale = scaleBand({
        domain: data.map(value => value.date),
        padding: 0.2,
    })

    const typeScale = scaleBand({
        domain: statuses,
        padding: 0.1,
    })

    const costScale = scaleLinear({
        domain: [0, Math.max(...data.map((d) => Math.max(...statuses.map((key) => Number(d[key])))))],
    })

    const xMax = width - margin.left - margin.right
    const yMax = height - margin.top - margin.bottom

    dateScale.rangeRound([0, xMax])
    costScale.range([yMax, 0])

    const scaleTickLabelProps = {
        fill: theme.palette["surfaces/surface-black"].main,
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fontWeight: 600,
        textAnchor: "middle",
    }

    const getBarColor = (item) => {
        const colorKey = item === "done" ? "cta-bg/cta-bg-primary" : "surfaces/surface-clair"
        return theme.palette[colorKey].main
    }
    const calculatedScale = xMax < CHART_MIN_WIDTH ? xMax / CHART_MIN_WIDTH : 1

    const initialZoom = useMemo(() => ({
        scaleX: calculatedScale,
        scaleY: 1,
        translateX: 0,
        translateY: 0,
        skewX: 0,
        skewY: 0,
    }), [calculatedScale])

    const xAxisTickValues = useMemo(() => {
        const maxTicks = 6
        const allDataValues = data.reduce((acc, v) => {
            return acc.concat([v.done, v.planned])
        }, [])

        const minValue = Math.floor(minBy(allDataValues))
        const maxValue = Math.ceil(maxBy(allDataValues))
        const tickStep = Math.ceil((maxValue - minValue) / (maxTicks))

        return Array(maxTicks).fill("empty").map((_, index) => {
            return (index + 1) * tickStep
        })
    }, [data])

    return (
        <Styled.Wrapper className={className}>
            <Styled.ChartContainer>
                <Styled.Container ref={ref}>
                    {xMax > 0 && <Zoom
                        height={height}
                        initialTransformMatrix={initialZoom}
                        wheelDelta={() => {
                            return {
                                scaleX: 1,
                                scaleY: 1,
                            }
                        }}
                        width={width}
                        constrain={(transformMatrix, prevTransformMatrix) => {
                            return constrainBandwithZoom(xMax, calculatedScale, transformMatrix, prevTransformMatrix)
                        }}
                    >
                        {zoom => {
                            const dateZoomScale = rescaleChartBandwidth(dateScale, zoom, margin)
                            typeScale.rangeRound([0, dateZoomScale.bandwidth()])

                            return <Styled.ZoomContainer>
                                <svg
                                    ref={zoom.containerRef}
                                    height={height - margin.bottom}
                                    style={{
                                        bottom: margin.bottom,
                                        cursor: zoom.isDragging ? "grabbing" : "grab",
                                        height: yMax,
                                        left: margin.left,
                                        position: "absolute",
                                        touchAction: "none",
                                    }}
                                    width={width - margin.left}
                                >
                                    <rect
                                        width={width}
                                        height={height}
                                        fill="transparent"
                                        onMouseDown={(e) => {
                                            e.stopPropagation()
                                            zoom?.dragStart(e)
                                        }}
                                        onMouseUp={(e) => {
                                            e.stopPropagation()
                                            zoom?.dragEnd(e)
                                        }}
                                        onTouchEnd={(e) => {
                                            e.stopPropagation()
                                            zoom?.dragEnd(e)
                                        }}
                                        onTouchMove={(e) => {
                                            e.stopPropagation()
                                            zoom?.dragMove(e)
                                        }}
                                        onTouchStart={(e) => {
                                            e.stopPropagation()
                                            zoom?.dragStart(e)
                                        }}
                                        rx={14}
                                        x={0}
                                        y={0}
                                    />
                                    <Group>
                                        <GridRows
                                            scale={costScale}
                                            width={width - margin.left}
                                            height={height}
                                            strokeDasharray="3"
                                            stroke="#D7D7D7"
                                            tickValues={xAxisTickValues}
                                        />
                                        <BarGroup
                                            color={(data) => getBarColor(data)}
                                            data={data}
                                            keys={statuses}
                                            height={yMax}
                                            x0={value => value.date}
                                            x0Scale={dateZoomScale}
                                            x1Scale={typeScale}
                                            yScale={costScale}
                                        >
                                            {(barGroups) => {
                                                return barGroups.map((barGroup) => (
                                                    <Group
                                                        key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                                                        left={barGroup.x0}>
                                                        {barGroup.bars.map((bar, index) => {
                                                            const key = `bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`
                                                            const paddingLeft = index % 2 * INITIAL_BAR_PADDING_LEFT * 2
                                                            const left = bar.x - INITIAL_BAR_PADDING_LEFT + paddingLeft
                                                            return (
                                                                <g key={`${key}-group`}>
                                                                    <rect
                                                                        key={key}
                                                                        x={left}
                                                                        y={bar.y}
                                                                        width={bar.width}
                                                                        height={bar.height}
                                                                        fill={bar.color}
                                                                        rx={4}
                                                                    />
                                                                    <rect
                                                                        key={`${key}-hoverable`}
                                                                        x={left - HOVERABLE_PADDING}
                                                                        y={bar.y - HOVERABLE_PADDING}
                                                                        width={bar.width + 2 * HOVERABLE_PADDING}
                                                                        height={bar.height + 2 * HOVERABLE_PADDING}
                                                                        fill="transparent"
                                                                        onMouseLeave={hideTooltip}
                                                                        onMouseMove={() => {
                                                                            showTooltip({
                                                                                tooltipData: bar,
                                                                                tooltipLeft: left + barGroup.x0 + INITIAL_BAR_PADDING_LEFT * 2,
                                                                                tooltipTop: bar.y,
                                                                            })
                                                                        }}
                                                                        onTouchStart={e => e.stopPropagation()}
                                                                        onMouseDown={e => e.stopPropagation()}
                                                                        rx={4}
                                                                    />
                                                                </g>
                                                            )
                                                        })}
                                                    </Group>
                                                ))
                                            }}
                                        </BarGroup>
                                    </Group>
                                </svg>
                                <Styled.AxisWrapper
                                    style={{
                                        left: margin.left,
                                        top: yMax + margin.top,
                                    }}
                                    height={30}
                                    width={width}
                                >
                                    <Styled.AxisBottom
                                        orientation={Orientation.bottom}
                                        scale={dateZoomScale}
                                        tickLabelProps={scaleTickLabelProps}
                                        strokeWidth={1.5}
                                    />
                                </Styled.AxisWrapper>
                                <Styled.AxisWrapper
                                    style={{
                                        left: 0,
                                        top: margin.top,
                                    }}
                                    height={height}
                                    width={margin.left}
                                >
                                    <Styled.Axis
                                        key="axis-values"
                                        left={margin.left}
                                        orientation={Orientation.left}
                                        scale={costScale}
                                        tickFormat={cost => `${formatNumberWithUnit(cost)}${currency}`}
                                        tickLabelProps={{
                                            ...scaleTickLabelProps,
                                            dx: -20,
                                            dy: 4,
                                        }}
                                        tickValues={xAxisTickValues}
                                        strokeWidth={1.5}
                                    />
                                </Styled.AxisWrapper>
                            </Styled.ZoomContainer>
                        }}
                    </Zoom>}
                    {tooltipOpen && tooltipData && (
                        <Styled.Tooltip
                            left={tooltipLeft}
                            top={tooltipTop + TOOLTIP_OFFSET_TOP}
                        >
                            <Styled.LegendTitle variant="Small">
                                {t(I18N_NAMESPACE, `components.roadworksBudgetsKpi.tooltip.${tooltipData?.key}`)}
                            </Styled.LegendTitle>
                            <Typography variant="Small">
                                {`${formatNumberWithUnit(tooltipData.value)}${currency}`}
                            </Typography>
                        </Styled.Tooltip>
                    )}
                </Styled.Container>
            </Styled.ChartContainer>
            <Styled.LegendsWrapper isMobile={isMobile}>
                <Styled.LegendWrapper>
                    <Styled.Color color={theme.palette["cta-bg/cta-bg-primary"].main} />
                    <Typography>
                        {t(I18N_NAMESPACE, "components.roadworksBudgetsKpi.legend.done")}
                    </Typography>
                </Styled.LegendWrapper>
                <Styled.LegendWrapper>
                    <Styled.Color color={theme.palette["surfaces/surface-clair"].main} />
                    <Typography>
                        {t(I18N_NAMESPACE, "components.roadworksBudgetsKpi.legend.planned")}
                    </Typography>
                </Styled.LegendWrapper>
            </Styled.LegendsWrapper>
        </Styled.Wrapper >
    )
}


RoadworksBudgetsKpiComponent.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.number.isRequired,
        done: PropTypes.oneOfType([PropTypes.number]),
        planned: PropTypes.oneOfType([PropTypes.number]),
    })).isRequired,
}