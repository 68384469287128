import { useCallback, useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ListRoundedIcon, MapRoundedIcon } from "@l2r-front/l2r-icons"
import { MobileModulePage } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { MobileFilterButton } from "../../components/MobileFilterButton"
import * as Styled from "./MobileIncidentsPage.styled"

export function MobileIncidentsPage() {

    const { t } = useTranslation(I18N_NAMESPACE)
    const navigate = useNavigateWithSearchParams()

    const incidentsListTabs = useMemo(() => ([
        {
            element: <Styled.IncidentPaginatedList />,
            icon: <ListRoundedIcon />,
            id: "tab-list",
            label: t(I18N_NAMESPACE, "pages.incidents.tabs.list"),
        },
        {
            element: <Styled.Map />,
            icon: <MapRoundedIcon />,
            id: "tab-map",
            label: t(I18N_NAMESPACE, "pages.incidents.tabs.map"),
            fullScreen: true,
        },
    ]), [t])

    const showFilters = useCallback(() => {
        return navigate("filters")
    }, [navigate])

    return <>
        <MobileFilterButton onClick={showFilters} />
        <MobileModulePage paramKey="tab-incident" tabsConfig={incidentsListTabs} />
    </>
}