import { onErrorFeedback, onSuccessFeedback, useAlertsDispatchContext, useL2rMutation } from "@l2r-front/l2r-query"

export function useRoadworksMutation(
    url,
    axiosFn,
    queryParameters,
    config = {},
) {
    const apiUrl = `roadwork/${url}`
    const { setSnackbar } = useAlertsDispatchContext()

    return useL2rMutation(
        apiUrl,
        axiosFn,
        { ...queryParameters },
        {
            ...config,
            onSuccess: (data) => onSuccessFeedback(config, setSnackbar, data),
            onError: () => onErrorFeedback(config, setSnackbar),
        },
    )
}