import { useCallback, useMemo, useState } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { FormControl, MenuItem, Typography, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { useMapContext } from "../../contexts/MapContext"
import * as Styled from "./MapLegend.styled"

export function MapLegend(props) {

    const {
        displayedLegends,
    } = props
    const [mapState, mapDispatch] = useMapContext()

    const { legendOpened } = mapState
    const { toggleLegendOpening } = mapDispatch
    const [selectedLegendIndex, setSelectedLegendIndex] = useState(0)
    const isMobile = useIsMobileDevice()
    const { t } = useTranslation(I18N_NAMESPACE)

    const selectOptions = useMemo(() => {
        return displayedLegends.map((v, index) => <MenuItem key={`select-option-${index}`} value={index}>
            {v.layerName}
        </MenuItem>)
    }, [displayedLegends])

    const Title = useMemo(() => {
        return displayedLegends.length > 1 ? () => <FormControl fullWidth>
            <Styled.Select
                noMargin
                onChange={(event) => setSelectedLegendIndex(event.target.value)}
                value={selectedLegendIndex}
                valueVariant="H4"
            >
                {selectOptions}
            </Styled.Select>
        </FormControl> : () => <Styled.TitleWrapper>
            <Typography id="map-legend-title" variant="H4">
                {displayedLegends?.[0]?.title}
            </Typography>
        </Styled.TitleWrapper>
    }, [displayedLegends, selectOptions, selectedLegendIndex])

    const displayLabel = useCallback((label) => {
        if (Array.isArray(label)) {
            return <Styled.LabelWrapper>
                {
                    label.map((labelValue => {
                        return <Typography variant="Regular">{labelValue}</Typography>
                    }))
                }
            </Styled.LabelWrapper>
        }
        return <Typography variant="Regular">{label}</Typography>
    }, [])

    if (!legendOpened) {
        return <Styled.Button
            color="cta-bg/cta-bg-primary"
            id="map-legend-open-button"
            isMobile={isMobile}
            onClick={toggleLegendOpening}
            vaiant="outlined"
        >
            {t(I18N_NAMESPACE, "components.legend.toggleButton.title")}
        </Styled.Button>
    }

    return <Styled.LegendWrapper id="map-legend" isMobile={isMobile} >
        <Styled.IconButton id="map-legend-close-button" onClick={toggleLegendOpening}>
            <Styled.CloseIcon />
        </Styled.IconButton>
        <Title />
        <Styled.OverflowWrapper isMobile={isMobile}>
            {displayedLegends[selectedLegendIndex].legendData.map((item, index) => {
                return <Styled.ItemWrapper
                    id={`map-legend-item-${index}`}
                    key={`legend-${index}`}
                    last={(index + 1) === displayedLegends[selectedLegendIndex].legendData.length}
                >
                    <Styled.Color color={item.color} />
                    {displayLabel(item.label)}
                </Styled.ItemWrapper>
            })}
        </Styled.OverflowWrapper>
    </Styled.LegendWrapper>
}

MapLegend.propTypes = {
    displayedLegends: PropTypes.arrayOf(
        PropTypes.shape({
            layerName: PropTypes.string.isRequired,
            legendData: PropTypes.arrayOf(
                PropTypes.shape({
                    color: PropTypes.string.isRequired,
                    label: PropTypes.oneOfType([
                        PropTypes.string.isRequired,
                        PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
                    ]).isRequired,
                }).isRequired,
            ).isRequired,
        }).isRequired,
    ).isRequired,
}