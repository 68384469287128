import { useCallback } from "react"

import { useNavigate } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useSearchParamsDispatchContext } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import {
    TechniqueItemCard,
} from "../../components/TechniqueItemCard"
import { STATUS_DONE, STATUS_TODO } from "../../constants/status"
import {
    useRoadworksDispatchContext,
    useRoadworksStateContext,
} from "../../contexts/RoadworksContext"
import { useRoadworkUpdate } from "../../hooks/mutations/useRoadworkUpdate"
import { formatRoadworkDoneDate } from "../../utils/formatting"

export function RoadworkCard(props) {
    const {
        currency,
        index,
        roadwork,
    } = props

    const readOnly = useRoleIsReadOnly()
    const { selectedRoadwork } = useRoadworksStateContext()
    const { setSelectedRoadwork } = useRoadworksDispatchContext()
    const { deleteParams } = useSearchParamsDispatchContext()
    const { t } = useTranslation(I18N_NAMESPACE)
    const navigate = useNavigate()

    const mutation = useRoadworkUpdate(roadwork, {}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.roadworkDetail.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.roadworkDetail.snackbar.success"),
    })

    const clickOnRoadwork = useCallback(() => {
        const unselectRoadwork = roadwork?.uuid === selectedRoadwork
        setSelectedRoadwork(unselectRoadwork ? null : roadwork.uuid)
    }, [roadwork?.uuid, selectedRoadwork, setSelectedRoadwork])

    const goToRoadworkDetails = () => {
        setSelectedRoadwork(roadwork?.uuid)
        deleteParams()
        navigate(`./${roadwork.uuid}`)
    }

    const handleDoRoadwork = () => {
        mutation.mutate({ status: roadwork?.status === STATUS_TODO ? STATUS_DONE : STATUS_TODO })
    }

    const isSelected = selectedRoadwork === roadwork.uuid
    const isDisabled = roadwork.status === STATUS_DONE
    const canBeDone = new Date().getFullYear() === roadwork.year && !readOnly
    const doneAt = roadwork.doneAt ? formatRoadworkDoneDate(roadwork.doneAt) : null

    const technique = {
        ...roadwork.technique,
        year: roadwork.year,
        linearLocation: roadwork.linearLocation,
    }

    return <TechniqueItemCard
        currency={currency?.symbol}
        doneAt={doneAt}
        handleClick={clickOnRoadwork}
        handleComplete={canBeDone ? handleDoRoadwork : null}
        handleGoToDetails={goToRoadworkDetails}
        id={`task-card-${index}${isSelected ? "-selected" : ""}`}
        isDisabled={isDisabled}
        isSelected={isSelected}
        technique={technique}
    />
}

RoadworkCard.propTypes = {
    currency: PropTypes.object,
    index: PropTypes.number.isRequired,
    roadwork: PropTypes.roadwork.isRequired,
}