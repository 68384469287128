import { useMemo } from "react"

import * as turf from "@turf/turf"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { Source, useMapDispatchContext } from "@l2r-front/l2r-map"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useSvraiStateContext } from "../../../../common/contexts/SvraiContext"
import { EVENT_TYPE_INCIDENT } from "../../constants/event"
import { useGeojsonEventsQuery } from "../../hooks/queries/events/useEvents"

export function IncidentsSource(props) {
    const { selectedIncidentUuid } = useSvraiStateContext()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { setError: setMapError } = useMapDispatchContext()
    const { data } = useGeojsonEventsQuery({
        type: EVENT_TYPE_INCIDENT,
        with_properties: false,
    }, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.incidentSource.error"),
        onError: () => setMapError(true),
    })

    // Need to copy id into properties because of that mapbox issue: 
    // link:https://github.com/mapbox/mapbox-gl-js/issues/2716
    const sanitizedData = useMemo(() => {
        if (!data) {
            return null
        }
        const dataFeatures = []
        turf.geomEach(data, (currentGeometry, _, featureProperties, featureBBox, featureId) => {
            if (featureId !== selectedIncidentUuid) {
                dataFeatures.push({
                    id: featureId,
                    bbox: featureBBox,
                    geometry: currentGeometry,
                    properties: {
                        ...featureProperties,
                        uuid: featureId,
                    },
                })
            }
        })
        return turf.featureCollection(dataFeatures)
    }, [data, selectedIncidentUuid])

    if (!sanitizedData) {
        return null
    }

    return (
        <Source {...props} type="geojson" data={sanitizedData} tolerance={.001} />
    )
}

IncidentsSource.propTypes = Source.propTypes