import { styled } from "@mui/material"

import { StreetViewRoundedIcon } from "@l2r-front/l2r-icons"
import { Button as UiButton } from "@l2r-front/l2r-ui"

export const Button = styled(UiButton)({
    bottom: 93,
    position: "absolute",
    right: 10,
})

export const Icon = styled(StreetViewRoundedIcon)(({ theme }) => ({
    marginLeft: theme.spacing(3),
}))