import { getPageFromUrl } from "../utils/urlHelpers/searchParams"

export type ApiPaginatedResponse<ModelType> = {
    next: string | null
    previous: string | null
    count: number
    results: ModelType[]
}

function getNextPageParam<ModelType>(lastPage: ApiPaginatedResponse<ModelType>) {
    if (!lastPage.next) {
        return undefined
    }
    return getPageFromUrl(lastPage.next)
}

function getPreviousPageParam<ModelType>(firstPage: ApiPaginatedResponse<ModelType>) {
    if (!firstPage.previous) {
        return undefined
    }
    return getPageFromUrl(firstPage.previous)
}


export const useInfiniteQueryConfig = {
    getNextPageParam,
    getPreviousPageParam,
}