import Alert from "@mui/material/Alert"
import Slide from "@mui/material/Slide"
import MuiSnackbar from "@mui/material/Snackbar"

import { PropTypes } from "@l2r-front/l2r-proptypes"

function SlideTransition(props) {
    return <Slide {...props} direction="up" />
}

export const Snackbar = (props) => {

    const {
        close,
        duration,
        id,
        label,
        open,
        severity,
        ...snackbarProps
    } = props

    return (
        <div>
            <MuiSnackbar
                autoHideDuration={duration ?? 2000}
                id={`snackbar-${severity}`}
                onClose={close}
                open={open}
                TransitionComponent={SlideTransition}
                {...snackbarProps}>
                <Alert
                    id={id}
                    key="snack"
                    onClose={close}
                    open={open}
                    severity={severity}
                    variant="filled"
                >
                    {label}
                </Alert>
            </MuiSnackbar>
        </div>
    )
}

Snackbar.propTypes = MuiSnackbar.propTypes & {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
}