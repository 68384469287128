import { useQueryClient } from "@tanstack/react-query"

import { useL2rQuery, useL2rQueries } from "./useL2rQuery"

export const useAuthenticatedQuery = (url, queryKey, queryParameters, config, initialData) => {
    const queryClient = useQueryClient()
    const query = useL2rQuery(url, queryKey, queryParameters, config, initialData)
    if (query?.error?.response?.status === 403) {
        queryClient.invalidateQueries("user")
        queryClient.refetchQueries("user")
    }
    return query
}

export function useAuthenticatedQueries(queriesData) {
    const queryClient = useQueryClient()
    const queries = useL2rQueries(queriesData)
    queries?.forEach(query => {
        if (query?.error?.response?.status === 403) {
            queryClient.invalidateQueries("user")
            queryClient.refetchQueries("user")
        }
    })

    return queries
}