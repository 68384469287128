import {
    onErrorFeedback,
    onSuccessFeedback,
    useAlertsDispatchContext,
    useL2rMutation,
} from "@l2r-front/l2r-query"

export function useAuthMutation(
    url,
    axiosFn,
    queryParameters,
    config = {},
) {
    const apiUrl = `auth/${url}`
    const { setSnackbar } = useAlertsDispatchContext()

    return useL2rMutation(
        apiUrl,
        axiosFn,
        queryParameters,
        {
            ...config,
            onSuccess: (...values) => onSuccessFeedback(config, setSnackbar, ...values),
            onError: (...values) => onErrorFeedback(config, setSnackbar, ...values),
        },
    )
}
