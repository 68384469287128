import { useMemo, useCallback, useEffect } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ListRoundedIcon, LocalSeeIcon, MapRoundedIcon } from "@l2r-front/l2r-icons"
import { MobileImageViewerContainer, useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { MobileModulePage } from "@l2r-front/l2r-ui"
import { useSearchParamsDispatchContext } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { PARAM_KEY_ROADWAY_DETAIL } from "../../../../common/constants/urlParams"
import { RoadwayDetailLayerIndicator } from "../../containers/RoadwayDetailLayerIndicator"
import { RoadwayMapLayersRouterMobile } from "../../routers/RoadwayMapLayersRouter"
import * as Styled from "./MobileRoadwayDetailPage.styled"

export function MobileRoadwayDetailPage() {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { resetImageCollectionType } = useImageCollectionDispatchContext()
    const { setParam } = useSearchParamsDispatchContext()

    const roadwayListTabs = useMemo(() => ([
        {
            element: <Styled.RoadwayDetailNotations />,
            icon: <ListRoundedIcon />,
            id: "tab-notations",
            label: t(I18N_NAMESPACE, "pages.roadwayDetail.tabs.notations"),
        },
        {
            element: <Styled.Map layerIndicator={<RoadwayDetailLayerIndicator />} >
                <RoadwayMapLayersRouterMobile />
            </Styled.Map>,
            icon: <MapRoundedIcon />,
            id: "tab-map",
            label: t(I18N_NAMESPACE, "pages.roadwayDetail.tabs.map"),
            fullScreen: true,
        },
        {
            element: <MobileImageViewerContainer />,
            icon: <LocalSeeIcon />,
            id: "tab-photo",
            label: t(I18N_NAMESPACE, "pages.roadwayDetail.tabs.photo"),
            fullScreen: true,
        },
    ]), [t])

    const tabChangeCallback = (oldValue) => {
        if (oldValue === "tab-photo") {
            resetImageCollectionType()
        }
    }

    const onImageClicked = useCallback(() => {
        setParam(PARAM_KEY_ROADWAY_DETAIL, "tab-photo")
    }, [setParam])

    useEffect(() => {
        document.addEventListener("imageClick", onImageClicked)

        return () => {
            document.removeEventListener("imageClick", onImageClicked)
        }
    }, [onImageClicked])

    return (
        <MobileModulePage paramKey={PARAM_KEY_ROADWAY_DETAIL} tabsConfig={roadwayListTabs} tabChangeCallback={tabChangeCallback} />
    )
}