import { client } from "@l2r-front/l2r-query"

import { useRoadworksQuery } from "../../../../common/hooks/queries/useRoadworksQuery"
import { constructTechniqueAcronym, sortTasks } from "../../utils/roadworkUtils"
import { roadworksQueryKeys } from "./queryKeys"
import { useTasks } from "./useTask"
import { useTaskTypes } from "./useTaskType"

function initialData(
    road,
    uuid,
) {
    const queries = client.getQueriesData(roadworksQueryKeys.list({ linear_location_road: road }))
    for (const [_, queryData] of queries) {
        if (!queryData) {
            continue
        }
        const roadwork = queryData.find((result) => {
            return result.uuid === uuid
        })

        if (roadwork) {
            return roadwork
        }
    }

    return undefined
}

export function useRoadwork(
    roadworkUuid,
    road,
    config = {},
) {
    const url = `roadworks/${roadworkUuid}/`
    const queryKey = roadworksQueryKeys["roadwork"](roadworkUuid)

    const {
        data: roadwork,
        isLoading: isLoadingRoadworks,
        isError: isErrorRoadworks,
    } = useRoadworksQuery(
        url,
        queryKey,
        {},
        {
            ...config,
            addProjectParam: false,
        },
        initialData(road, roadworkUuid),
    )

    const {
        data: taskTypes,
        isLoading: isLoadingTaskTypes,
        isError: isErrorTaskTypes,
    } = useTaskTypes()

    const isLoading = isLoadingRoadworks || isLoadingTaskTypes
    const isError = isErrorRoadworks || isErrorTaskTypes

    const data = roadwork ? {
        ...roadwork,
        technique: {
            ...roadwork?.technique,
            acronym: constructTechniqueAcronym(roadwork.technique, taskTypes),
        },
    } : null

    return { data, isLoading, isError }
}

export function useRoadworkWithTasks(
    roadworkUuid,
    road,
    config = {},
) {
    const {
        data: roadworkData,
        isLoading: roadworkLoading,
        isError: roadworkError,
    } = useRoadwork(roadworkUuid, road, config)

    const {
        data: tasksData,
        isError: tasksError,
        isLoading: tasksLoading,
    } = useTasks(roadworkData?.tasks, { enabled: !!roadworkData?.tasks })

    const {
        data: taskTypes,
        isLoading: taskTypesLoading,
        isError: taskTypesError,
    } = useTaskTypes()

    const tasks = sortTasks(tasksData, taskTypes)

    const isLoading = roadworkLoading || tasksLoading || taskTypesLoading
    const isError = roadworkError || tasksError || taskTypesError
    const data = (roadworkData && tasksData) ? { ...roadworkData, tasks: tasks }
        : null

    return { data, isLoading, isError }
}
