import { useMemo } from "react"

import { getYear } from "date-fns"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { appRoad } from "../../../../common/constants/appRoad"
import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { RoadworksYearlyBudgetKpiComponent } from "../../components/RoadworksYearlyBudgetKpi"
import { STATUS_DONE, STATUS_TODO } from "../../constants/status"
import { useRoadworksProject } from "../../hooks/queries/useRoadworksProjectQuery"
import { useRoadworksQuartersStats } from "../../hooks/queries/useRoadworksQuartersStats"
import * as Styled from "./RoadworksYearlyBudgetKpi.styled"

export function RoadworksYearlyBudgetKpi(props) {

    const { data: project, isError: isErrorProject, isLoading: isLoadingProject } = useRoadworksProject()
    const { data, isError: isErrorStats, isLoading: isLoadingStats } = useRoadworksQuartersStats()

    const isError = isErrorStats || isErrorProject
    const isLoading = isLoadingStats || isLoadingProject

    const { t } = useTranslation()
    const isMobile = useIsMobileDevice()
    const currentYear = getYear(new Date())

    const totalDoneCost = useMemo(() => {
        const costs = data?.cost?.[currentYear]
        return costs?.[STATUS_DONE].reduce((acc, numberFromApi) => {
            return acc + parseFloat(numberFromApi)
        }, 0)
    }, [currentYear, data])

    const totalCost = useMemo(() => {
        return totalDoneCost + parseFloat(data?.cost?.[currentYear]?.[STATUS_TODO])
    }, [currentYear, data, totalDoneCost])

    if (isError || (!isLoading && !totalCost && totalCost !== 0)) {
        return <Styled.KpiError
            {...props}
            title={t(I18N_NAMESPACE, "containers.notationKpi.error")}
            id="roadworks-budget-kpi-error"
            message={t(I18N_NAMESPACE, "containers.notationKpi.error")}
            path={`${appRoad.path}/${appRoad.modules.roadworks.path}`}
        />
    }

    if (isLoading) {
        return <Styled.KpiSkeleton {...props} />
    }

    return <Styled.KpiCard
        {...props}
        id="roadworks-quarters-kpi"
        path={`${appRoad.path}/${appRoad.modules.roadworks.path}`}
    >
        <Styled.Title isMobile={isMobile} variant="H1">
            {t(I18N_NAMESPACE, "containers.roadworksYearlyBudgetKpi.title", {
                year: currentYear,
            })}
        </Styled.Title>
        <Styled.ChartContainer>
            {data ? <RoadworksYearlyBudgetKpiComponent
                budget={totalCost}
                currency={project?.currency?.symbol}
                quartersCost={data.cost}
                totalCost={totalCost}
            />
                : <div>{t(I18N_NAMESPACE, "containers.roadworksYearlyBudgetKpi.noData")}</div>}
        </Styled.ChartContainer>
    </Styled.KpiCard>
}