import { http, HttpResponse } from "msw"

import {
    adminLogiroad,
    allNetworks,
    allTaskTypes,
    getFeature,
    getFeatures,
    getImageCollectionsBySlug,
    getNetworkBySlug,
    getRoadProjectBySlug,
    getRoadSurveysBySlug,
    getRoadNetworkGradings,
    getRoadNetworkConditions,
    getDamagesStatsByNetworkCondition,
    getTechniques,
    getFiltersParams,
    roadworksYearsStats,
    tasks,
    getRoadworks,
    getRoadworksProjectBySlug,
    svraiProjectEmpty,
    getTaskTypeByCode,
    getRoadworksBySlug,
    quartersStats,
    getRoadworkById,
    mockImageUrl,
    quartersStats2,
} from "../fixtures"


const response404 = () => HttpResponse.json(
    {
        "detail": "Not found.",
    },
    {
        status: 404,
    },
)

export const getHappyHandlers = (apiUrl) => {
    return [
        http.get(`${apiUrl}auth/me`, () => {
            return HttpResponse.json(adminLogiroad)
        }),

        http.get(`${apiUrl}network/networks`, () => {
            return HttpResponse.json(allNetworks)
        }),
        http.get(`${apiUrl}network/networks/:slug`, ({ params }) => {
            return HttpResponse.json(getNetworkBySlug(params.slug))
        }),

        http.get(`${apiUrl}svrai/projects/:slug`, () => HttpResponse.json(svraiProjectEmpty)),

        http.get(`${apiUrl}road/projects/:slug`, ({ params }) => {
            const project = getRoadProjectBySlug(params.slug)
            if (project) {
                return HttpResponse.json(project)
            } else {
                return response404()
            }
        }),
        http.get(`${apiUrl}road/surveys`, ({ request }) => {
            const url = new URL(request.url)
            const networkSlug = url.searchParams.get("project")

            return HttpResponse.json(getRoadSurveysBySlug(networkSlug))
        }),
        http.get(`${apiUrl}road/network_gradings`, ({ request }) => {
            const url = new URL(request.url)
            const networkSlug = url.searchParams.get("project")
            const year = url.searchParams.get("year")

            return HttpResponse.json(getRoadNetworkGradings(networkSlug, year))
        }),
        http.get(`${apiUrl}road/network_conditions`, ({ request }) => {
            const url = new URL(request.url)
            const networkSlug = url.searchParams.get("project")
            const survey = url.searchParams.get("survey")
            const year = url.searchParams.get("year")

            return HttpResponse.json(getRoadNetworkConditions(networkSlug, survey, year))
        }),
        http.get(`${apiUrl}road/damages/stats`, ({ request }) => {
            const url = new URL(request.url)
            const networkCondition = url.searchParams.get("network_condition")

            return HttpResponse.json(getDamagesStatsByNetworkCondition(networkCondition))
        }),

        http.get(`${apiUrl}roadwork/projects/:slug`, ({ params }) => {
            const project = getRoadworksProjectBySlug(params.slug)
            if (project) {
                return HttpResponse.json(project)
            } else {
                return response404()
            }
        }),
        http.get(`${apiUrl}roadwork/roadworks/techniques`, ({ request }) => {
            const url = new URL(request.url)
            const filtersParams = getFiltersParams(url)
            const networkSlug = url.searchParams.get("project")

            return HttpResponse.json(getTechniques(networkSlug, filtersParams))
        }),
        http.get(`${apiUrl}roadwork/roadworks`, ({ request }) => {
            const url = new URL(request.url)
            const networkSlug = url.searchParams.get("project")
            const road = url.searchParams.get("linear_location_road")
            const filters = getFiltersParams(url)

            return HttpResponse.json(getRoadworks(networkSlug, road, filters))
        }),
        http.get(`${apiUrl}roadwork/roadworks/:roadworkUuid`, ({ params }) => {
            const roadworkUuid = params.roadworkUuid

            const roadwork = getRoadworkById(roadworkUuid)
            if (roadwork) {
                return HttpResponse.json(roadwork)
            } else {
                return response404()
            }
        }),
        http.patch(`${apiUrl}roadwork/roadworks/:roadworkUuid`, async ({ params, request }) => {
            const roadworkUuid = params.roadworkUuid
            const patchedData = await request.json()

            const roadwork = getRoadworkById(roadworkUuid)
            const patchedRoadwork = {
                ...roadwork,
                ...patchedData,
            }
            const statusIsDone = patchedRoadwork.status === 100
            if (roadwork) {
                return HttpResponse.json({
                    ...patchedRoadwork,
                    doneAt: statusIsDone ? (new Date()).toISOString() : null,
                    doneBy: statusIsDone ? "John Doe" : null,
                })
            } else {
                return response404()
            }
        }),
        http.get(`${apiUrl}roadwork/tasks/:taskUuid`, ({ params }) => {
            const taskUuid = params.taskUuid
            const task = tasks[taskUuid]

            if (task) {
                return HttpResponse.json(task)
            } else {
                return response404()
            }
        }),
        http.patch(`${apiUrl}roadwork/tasks/:taskUuid`, async ({ params, request }) => {
            const taskUuid = params.taskUuid
            const task = tasks[taskUuid]
            const data = await request.json()
            const res = { ...task, ...data }
            return HttpResponse.json(res)
        }),
        http.post(`${apiUrl}roadwork/tasks/:taskUuid`, async ({ request }) => {
            const data = await request.json()
            return HttpResponse.json(data)
        }),
        http.delete(`${apiUrl}roadwork/tasks/:taskUuid`, ({ params }) => {
            const taskUuid = params.taskUuid
            return HttpResponse.json({ uuid: taskUuid })
        }),
        http.get(`${apiUrl}roadwork/task_types/:taskCode`, ({ params }) => {
            const taskType = getTaskTypeByCode(params.taskCode)
            if (taskType) {
                return HttpResponse.json(taskType)
            } else {
                return response404()
            }
        }),
        http.get(`${apiUrl}roadwork/task_types`, () => {
            return HttpResponse.json(allTaskTypes)
        }),
        http.get(`${apiUrl}roadwork/network_roadworks/`, ({ request }) => {
            const url = new URL(request.url)
            const networkSlug = url.searchParams.get("project")

            return HttpResponse.json(getRoadworksBySlug(networkSlug))
        }),

        http.get(`${apiUrl}geoserver/features/:featureId`, ({ request, params }) => {
            const url = new URL(request.url)
            const asGeoJSON = !!url.searchParams.get("as_geojson")

            const feature = getFeature(params.featureId, asGeoJSON)

            if (feature) {
                return HttpResponse.json(feature)
            } else {
                return response404()
            }
        }),
        http.get(`${apiUrl}geoserver/features`, ({ request }) => {
            const url = new URL(request.url)
            const layer = url.searchParams.get("layer")
            const road = url.searchParams.get("linear_location_road")
            const absStart = url.searchParams.get("linear_location_start")
            const absEnd = url.searchParams.get("linear_location_end")
            const asGeoJSON = !!url.searchParams.get("as_geojson")

            return HttpResponse.json(getFeatures(layer, road, absStart, absEnd, asGeoJSON))
        }),
        http.get(`${apiUrl}roadwork/roadworks/stats/quarters`, ({ request }) => {
            const url = new URL(request.url)
            const year = url.searchParams.get("year")
            return HttpResponse.json(quartersStats[year])
        }, { once: true }),
        http.get(`${apiUrl}roadwork/roadworks/stats/quarters`, ({ request }) => {
            const url = new URL(request.url)
            const year = url.searchParams.get("year")
            return HttpResponse.json(quartersStats2[year])
        }),
        http.get(`${apiUrl}roadwork/roadworks/stats/years`, ({ request }) => {
            const url = new URL(request.url)
            const networkSlug = url.searchParams.get("project")
            const stats = roadworksYearsStats[networkSlug]

            return HttpResponse.json(stats)
        }),
        http.get(`${apiUrl}roadwork/task_types`, () => {
            return HttpResponse.json(allTaskTypes)
        }),
        http.get(`${apiUrl}photo/collections`, ({ request }) => {
            const url = new URL(request.url)
            const network = url.searchParams.get("network")
            const date = url.searchParams.get("date__lte")

            return HttpResponse.json(getImageCollectionsBySlug(network, date))
        }),
        http.get(`${apiUrl}photo/photos/:imageId`, () => {
            return HttpResponse.json({ image: mockImageUrl })
        }),
        http.get("https://shotlogiroadcenter.blob.core.windows.net/*", async () => {
            const buffer = await fetch("assets/mockImage.jpg")
                .then((response) => response.arrayBuffer())

            return HttpResponse.arrayBuffer(buffer, {
                headers: {
                    "Content-Type": "image/jpeg",
                },
            })
        }),

    ]
}