import { useMemo } from "react"

import { getQuarter } from "date-fns"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { STATUS_DONE, STATUS_TODO } from "../../constants/status"
import { formatNumberWithUnit } from "../../utils/formatting"
import * as Styled from "./RoadworkQuartersBudget.styled"

export function RoadworkQuartersBudget(props) {

    const {
        budget,
        currency,
        quartersCost,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const currentQuarter = getQuarter(new Date())

    const quarters = useMemo(() => {
        const values = Object.values(quartersCost)[0]

        const doneValues = values[STATUS_DONE]
            .slice(0, currentQuarter)
            .map((numberFromApi, index) => {
                const costNumber = parseFloat(numberFromApi)
                const width = costNumber * 100 / budget
                return ({
                    cost: formatNumberWithUnit(numberFromApi),
                    label: `${t(I18N_NAMESPACE, "components.roadworkQuartersBudget.label")} ${index + 1}`,
                    status: STATUS_DONE,
                    width,
                })
            })

        const todoValue = {
            cost: formatNumberWithUnit(values[STATUS_TODO]),
            status: STATUS_TODO,
            width: parseFloat(values[STATUS_TODO]) / budget * 100,
        }
        return [...doneValues, todoValue]
    }, [budget, currentQuarter, quartersCost, t])

    return <Styled.RoadworkQuartersBudgetWrapper>
        {quarters.map((quarter, index) => <Styled.RoadworkQuarterBudget
            {...quarter}
            currency={currency}
            key={`quarter-${index}`}
            id={`quarter-${index}`}
        />)}
    </Styled.RoadworkQuartersBudgetWrapper>
}

RoadworkQuartersBudget.propTypes = {
    budget: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    quartersCost: PropTypes.quartersCost.isRequired,
}