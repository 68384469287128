import { styled, FormikAutoComplete, FormikSelect, FormikTextField, TextField, Typography } from "@l2r-front/l2r-ui"

export const FormWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(5, 3),
}))

export const InputsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    marginBottom: theme.spacing(7),
}))

export const ButtonsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(6),
    justifyContent: "flex-end",
}))

export const TaskTypeContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(10),
    alignItems: "center",
}))

export const TaskTypeSelect = styled(FormikSelect)(() => ({
    flexGrow: 1,
}))

export const InfoContainer = styled("div")((({ theme }) => ({
    alignItems: "center",
    backgroundColor: theme.palette["border/border-variation"].main,
    borderRadius: theme.spacing(3),
    display: "flex",
    margin: theme.spacing(4),
    padding: theme.spacing(2, 3),
})))

export const InfoText = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(3),
}))

export const TaskCategory = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(3),
    padding: theme.spacing(1, 4),
}))

export const QuantitySelect = styled(FormikTextField)(() => ({
    "& .MuiInputAdornment-root": {
        height: "100%",
    },
}))

export const UnitSelect = styled(FormikAutoComplete)(() => ({
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
}))

export const TextFieldInput = styled(TextField)(() => ({
    width: 150,

    "input": {
        textAlign: "right",
    },
}))
