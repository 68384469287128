import { useCallback, useMemo } from "react"

import * as turf from "@turf/turf"

import { useImageCollectionStateContext, useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { ReactMapGl, Map as L2rMap, useMapContext, MapLegend } from "@l2r-front/l2r-map"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useRoadwayStateContext } from "../../../modules/roadway/contexts/RoadwayContext"
import { useIsFetchingRoadway } from "../../../modules/roadway/hooks/useIsFetchingRoadway"
import { useRoadworksStateContext } from "../../../modules/roadworks/contexts/RoadworksContext"

export function Map(props) {

    const {
        children,
        ...mapProps
    } = props

    const isFetchingRoadway = useIsFetchingRoadway()
    const { selectedNetwork } = useNetworksStateContext()
    const [mapState, mapDispatch] = useMapContext()
    const { onOpenImageViewer, onSwitchImageLayer, isImageCollectionAvailable } = useImageCollectionDispatchContext()
    const { imageLayerDisplayed } = useImageCollectionStateContext()
    const { setMapBoundingBoxToFit } = mapDispatch
    const { error } = mapState
    const { displayedLayers: notationLayer } = useRoadwayStateContext()
    const { displayedLayers: roadworkLayer } = useRoadworksStateContext()
    const displayedLayers = notationLayer || roadworkLayer

    const mapInitialViewState = useMemo(() => {
        const [minLng, minLat, maxLng, maxLat] = turf.bbox(selectedNetwork.boundingBox)
        return {
            longitude: (minLng + maxLng) / 2,
            latitude: (minLat + maxLat) / 2,
            zoom: 8,
        }
    }, [selectedNetwork])

    const onRecenterOnNetworkBoundingBox = useCallback(() => {
        const [minLng, minLat, maxLng, maxLat] = turf.bbox(selectedNetwork.boundingBox)
        setMapBoundingBoxToFit({
            minLng,
            minLat,
            maxLng,
            maxLat,
        })
    }, [selectedNetwork, setMapBoundingBoxToFit])

    const onSwitchImageLayerCallback = isImageCollectionAvailable() ? onSwitchImageLayer : undefined

    return (
        <L2rMap
            dragRotate={false}
            error={error}
            initialViewState={mapInitialViewState}
            isLoading={!!isFetchingRoadway}
            imageLayerDisplayed={imageLayerDisplayed}
            onOpenImageViewer={onOpenImageViewer}
            onSwitchImageLayer={onSwitchImageLayerCallback}
            onRecenter={onRecenterOnNetworkBoundingBox}
            {...mapProps}
        >
            {displayedLayers && <MapLegend displayedLegends={displayedLayers} />}
            {children}
        </L2rMap>
    )
}

Map.propTypes = {
    ...ReactMapGl.propTypes,
    className: PropTypes.string,
}