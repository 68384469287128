import React from "react"

import * as Sentry from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"

import "@l2r-front/l2r-i18n"
import { AuthenticationContextProvider, MyUserContextProvider } from "@l2r-front/l2r-auth"
import { AlertsContextProvider, client } from "@l2r-front/l2r-query"
import { theme, ThemeProvider } from "@l2r-front/l2r-ui"
import { ResourceLoader, SearchParamsContextProvider } from "@l2r-front/l2r-utils"

import { ErrorPage } from "../pages/ErrorPage"

export const appProviders = [
    <ThemeProvider theme={theme} />,
    <Sentry.ErrorBoundary fallback={({ resetError }) => <ErrorPage resetError={resetError} />} />,
    <QueryClientProvider client={client} />,
    <ResourceLoader />,
    <SearchParamsContextProvider />,
    <AlertsContextProvider />,
    <AuthenticationContextProvider />,
    <MyUserContextProvider />,
]