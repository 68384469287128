import { useCallback, useMemo } from "react"

import { getYear } from "date-fns"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Collapse } from "@l2r-front/l2r-ui"

import { FILTERS } from "../../constants/filters"
import {
    TechniquesFiltersFormError,
    TechniquesFiltersFormPost,
    TechniquesFiltersFormSkeleton,
} from "../../forms/TechniquesFiltersForm"
import { useGetProjectTechniqueCodes } from "../../hooks/queries/useGetProjectTechniqueCodes"
import { useRoadworksProject } from "../../hooks/queries/useRoadworksProjectQuery"

export function TechniquesFilters(props) {

    const {
        enabledFilters,
        className,
        close,
        displayFilters,
    } = props

    const { data: project, isLoading: isLoadingProject, isLoading: isErrorProject } = useRoadworksProject()
    const { data: techniqueCodes, isLoading: isLoadingTechniques, isLoading: isErrorTechniques } = useGetProjectTechniqueCodes()

    const displayedYears = useMemo(() => {
        if (project) {
            const nowYear = getYear(Date.now())
            const roadworksYears = [...project.roadworksYears]
            const years = roadworksYears?.includes(nowYear) ? roadworksYears
                : roadworksYears.concat(nowYear).sort()
            return years
        }
        return null
    }, [project])

    const TechniquesFiltersContent = useCallback(() => {
        if (isErrorProject || isErrorTechniques) {
            return <TechniquesFiltersFormError />
        } else if (isLoadingProject || isLoadingTechniques) {
            return <TechniquesFiltersFormSkeleton />
        }
        return <TechniquesFiltersFormPost enabledFilters={enabledFilters} close={close} years={displayedYears} techniques={techniqueCodes} />
    }, [close, displayedYears, enabledFilters, techniqueCodes, isErrorProject, isLoadingProject, isLoadingTechniques, isErrorTechniques])

    return <div className={className} >
        <Collapse in={displayFilters}>
            <TechniquesFiltersContent />
        </Collapse>
    </div>
}

TechniquesFilters.defaultProps = {
    enabledFilters: FILTERS,
}

TechniquesFilters.propTypes = {
    enabledFilters: PropTypes.arrayOf(PropTypes.oneOf(FILTERS)),
    className: PropTypes.string,
    close: PropTypes.func.isRequired,
    displayFilters: PropTypes.bool.isRequired,
}