import { useCallback, useMemo } from "react"

import { useIsFetching } from "@tanstack/react-query"
import * as turf from "@turf/turf"

import { ReactMapGl, Map as L2rMap, useMapContext } from "@l2r-front/l2r-map"
import { NetworkReferentialLayer, useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { MapLayersRouter } from "./MapLayers.router"

export function Map(props) {

    const {
        children,
        ...mapProps
    } = props

    const isFetchingEvent = useIsFetching(["events"])
    const isFetchingReferential = useIsFetching(["networks", "referential"])
    const { selectedNetwork } = useNetworksStateContext()
    const [mapState, mapDispatch] = useMapContext()
    const { setMapBoundingBoxToFit } = mapDispatch

    const { error } = mapState

    const mapInitialViewState = useMemo(() => {
        const [minLng, minLat, maxLng, maxLat] = turf.bbox(selectedNetwork.boundingBox)
        return {
            longitude: (minLng + maxLng) / 2,
            latitude: (minLat + maxLat) / 2,
            zoom: 8,
        }
    }, [selectedNetwork])

    const onRecenterOnNetworkBoundingBox = useCallback(() => {
        const [minLng, minLat, maxLng, maxLat] = turf.bbox(selectedNetwork.boundingBox)
        setMapBoundingBoxToFit({
            minLng,
            minLat,
            maxLng,
            maxLat,
        })
    }, [selectedNetwork, setMapBoundingBoxToFit])

    return (
        <L2rMap
            dragRotate={false}
            error={error}
            initialViewState={mapInitialViewState}
            isLoading={!!isFetchingEvent || !!isFetchingReferential}
            onRecenter={onRecenterOnNetworkBoundingBox}
            {...mapProps}
        >
            <NetworkReferentialLayer />
            <MapLayersRouter />
            {children}
        </L2rMap >
    )
}

Map.propTypes = {
    ...ReactMapGl.propTypes,
    className: PropTypes.string,
}