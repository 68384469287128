import { useQueryClient } from "@tanstack/react-query"

import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { useSvraiMutation } from "../../../../../common/hooks/mutations/useSvraiMutation"
import { svraiQueryKeys } from "../../../../../common/hooks/queries/queryKeys"
import { eventsQueryKeys } from "../../queries/events/queryKeys"

export function useEventTagsUpdate(
    eventId,
    queryParameters,
    config = {},
) {
    const url = `events/${eventId}/`
    const queryClient = useQueryClient()

    return useSvraiMutation(
        url,
        axiosInstance.patch,
        {
            ...queryParameters,
        },
        {
            ...config,
            onSuccess: () => {
                queryClient.invalidateQueries(svraiQueryKeys["network"]())
                queryClient.refetchQueries(svraiQueryKeys["network"]())
                queryClient.invalidateQueries(eventsQueryKeys["list"]())
                queryClient.invalidateQueries(eventsQueryKeys["detail"](eventId))
                queryClient.invalidateQueries({
                    predicate: (query) => {
                        const queryParams = query?.queryKey?.[2]
                        const geoJSONKey = eventsQueryKeys["geoJSON"]()
                        geoJSONKey.pop()
                        const isGeoJSONQuery = geoJSONKey.every(key => query.queryKey.includes(key))
                        const isTagFilteredQuery = Object.keys(queryParams || {}).includes("tags")

                        return isGeoJSONQuery && isTagFilteredQuery
                    },
                })
            },
        },
    )
}