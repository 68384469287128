import { computeBrushParametrics, computeBrushRadiusAtMousePosition, createParametrics, initStateFromFeatures, initStateFromRoadworkFeatures } from "../utils"

export let RubberMode = {}

RubberMode.onSetup = function (opts) {
    var state = {}

    state.roadSegments = opts.roadSegments
    state.startingFeatures = opts.startingFeatures

    this.map["dragPan"].disable()
    this.map.getCanvas().style.cursor = "url(assets/resources/circle_cursor.png) 32 32, pointer"

    const currentFeatures = opts.drawFeatures || []

    if (currentFeatures.length || opts.hasBeenUpdated) {
        state.parametricSegments = []
        state.featureIds = []

        initStateFromFeatures(state, currentFeatures)
    } else {
        const defaultParametrics = initStateFromRoadworkFeatures(state)
        createParametrics(this, state, defaultParametrics)
    }

    return state
}

RubberMode.onDrag = function (state, e) {
    if (this.map && state.roadSegments) {
        const brushRadius = computeBrushRadiusAtMousePosition(this, e)

        const paintedParametrics = computeBrushParametrics(state, brushRadius, e)

        if (paintedParametrics.length) {
            const featuresUpdated = createParametrics(this, state, paintedParametrics, false)

            if (featuresUpdated) {
                this.map.fire("draw.paint_updated")
            }
        }
    }
}

RubberMode.onMouseDown = function (state, e) {
    this.onDrag(state, e)
}

RubberMode.toDisplayFeatures = function (state, geojson, display) {
    display(geojson)
}

RubberMode.onTrash = function (state) {
    const defaultParametrics = initStateFromRoadworkFeatures(state)
    createParametrics(this, state, defaultParametrics)
}